/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Divider,
  CardContent,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { applyForNewPermission, getAllLocationDetails } from 'src/Services/apiService';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DimensionDataTable from './DimensionDataTable';

// ----------------------------------------------------------------------

export default function ApplyNewPermission() {
  const navigate = useNavigate();

  const [applicantName, setApplicantName] = useState({
    name: '',
    agencyName: '',
    status: '',
    address: '',
    city: 'Jalgaon',
    phoneNumber: '',
    emailAddress: '',
  });

  const [advertisementDetails, setAdvertisementDetails] = useState({
    medium: '',
    location: '',
    form: '',
    nature: '',
    purpose: '',
    periodFrom: dayjs(),
    periodTo: dayjs(),
    totalDays: 1,
    messageText: '',
    dimensionsLength: 0,
    dimensionsWidth: 0,
    dimensionsHeight: 0,
    dimensionsTotalArea: 0,
    siteLocation: {
      roadName: '',
      roadWidth: 0,
      numberOfHoardingWithin25m: 0,
      numberOfTreesWithin25m: 0,
      treesCutRequired: 'No',
    },
    landlord: {
      name: '',
      nocSubmitted: 'No',
    },
    documentsSubmitted: 'false',
  });

  const [locationData, setLocationData] = useState([]);
  // const [role, setRole] = useState(localStorage.role);
  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState({
    name: { errorMsg: '' },
    email: { errorMsg: '' },
    password: { errorMsg: '' },
  });

  useEffect(() => {
    getAllLocation();
  }, []);

  const getAllLocation = async () => {
    const locations = await getAllLocationDetails();
    setLocationData(locations);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const totalDaysAdv = advertisementDetails.periodTo.diff(advertisementDetails.periodFrom, 'day');
    console.log('🚀 ~ submitHandler ~ totalDaysAdv:', totalDaysAdv);
    if (totalDaysAdv > 0) {
      advertisementDetails.totalDays = totalDaysAdv;
    } else {
      advertisementDetails.totalDays = totalDaysAdv + 1;
    }
    const applicationData = {
      applicant: applicantName,
      advertisementDetails,
    };
    const applyForNewPermissionRes = await applyForNewPermission(applicationData);
    console.log('🚀 ~ submitHandler ~ applicationData:', applyForNewPermissionRes);
    if (applyForNewPermissionRes._id) {
      toast.success('Application Submitted Successfully');
      navigate('/list-of-application');
    } else {
      toast.error('Application Submission Failed');
    }
  };

  return (
    <>
      <Helmet>
        <title> Apply For Renewal | Smart Genesis </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Apply For Renewal
          </Typography>
        </Stack>

        <Card>
          {/* <CardHeader subheader="All fields are required" title="Create New Work Order" /> */}
          <Divider />
          <CardContent>
            <form onSubmit={submitHandler.bind()}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    label="Name of Applicant"
                    value={applicantName.name}
                    error={errorMsg.name.errorMsg.length > 0}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, name: e.target.value });
                    }}
                    helperText={errorMsg.name.errorMsg}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="agencyName"
                    label="Name of Agency"
                    value={applicantName.agencyName}
                    error={errorMsg.email.errorMsg.length > 0}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, agencyName: e.target.value });
                    }}
                    helperText={errorMsg.email.errorMsg}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Status"
                      value={applicantName.status}
                      onChange={(e) => {
                        setApplicantName({ ...applicantName, status: e.target.value });
                      }}
                    >
                      <MenuItem value={'Proprietary firm'}>Proprietary firm</MenuItem>
                      <MenuItem value={'Company'}>Company</MenuItem>
                      <MenuItem value={'Charitable Trust'}>Charitable Trust</MenuItem>
                      <MenuItem value={'Others'}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.address}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, address: e.target.value });
                    }}
                    label="Address"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.city}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, city: e.target.value });
                    }}
                    label="City"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.phoneNumber}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, phoneNumber: e.target.value });
                    }}
                    label="Phone Number"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.emailAddress}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, emailAddress: e.target.value });
                    }}
                    label="Email Address"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Medium of advertisements applied for</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Medium of advertisements applied for"
                      value={advertisementDetails.medium}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, medium: e.target.value });
                      }}
                    >
                      <MenuItem value={'Illuminated'}>Illuminated</MenuItem>
                      <MenuItem value={'Non Illuminated'}>Non Illuminated</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  {/* <InputLabel id="demo-simple-select-label">Locations: </InputLabel> */}
                  <Autocomplete
                    options={locationData}
                    autoHighlight
                    getOptionLabel={(option) => option.fullAddress}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        disabled={option.isBooked}
                      >
                        {option.fullAddress} ({option.measurement.width}*{option.measurement.height})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a Location"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, option) => {
                      setAdvertisementDetails({
                        ...advertisementDetails,
                        location: option._id,
                        dimensionsWidth: option.measurement.width,
                        dimensionsHeight: option.measurement.height,
                        dimensionsLength: option.measurement.height / 2,
                        dimensionsTotalArea: option.measurement.width * option.measurement.height,
                      });
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Form of Advertisement</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Form of Advertisement"
                      value={advertisementDetails.form}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, form: e.target.value });
                      }}
                    >
                      <MenuItem value={'Illuminated'}>Temporary</MenuItem>
                      <MenuItem value={'Non Illuminated'}>Non Temporary</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={advertisementDetails.nature}
                    onChange={(e) => {
                      setAdvertisementDetails({ ...advertisementDetails, nature: e.target.value });
                    }}
                    label="Nature of advertisement applied"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.purpose}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, purpose: e.target.value });
                      }}
                      label="Purpose"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Period From"
                        value={advertisementDetails.periodFrom}
                        onChange={(newValue) => {
                          setAdvertisementDetails({ ...advertisementDetails, periodFrom: newValue });
                        }}
                        format="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Period To"
                        value={advertisementDetails.periodTo}
                        onChange={(newValue) => {
                          setAdvertisementDetails({ ...advertisementDetails, periodTo: newValue });
                        }}
                        format="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.messageText}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, messageText: e.target.value });
                      }}
                      label="Text of the message on banner"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel id="demo-simple-select-label">Dimensions: </InputLabel>
                  <FormControl fullWidth>
                    <DimensionDataTable dimensionData={advertisementDetails} />
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel id="demo-simple-select-label">Name of the Road drawing visibility: </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.siteLocation.roadName}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            roadName: e.target.value,
                          },
                        }));
                      }}
                      label="Name of the Road :"
                    />

                    <TextField
                      fullWidth
                      type="number"
                      value={advertisementDetails.siteLocation.roadWidth}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            roadWidth: e.target.value,
                          },
                        }));
                      }}
                      label="Width of the Road :"
                    />

                    <TextField
                      fullWidth
                      type="number"
                      value={advertisementDetails.siteLocation.numberOfHoardingWithin25m}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            numberOfHoardingWithin25m: e.target.value,
                          },
                        }));
                      }}
                      label="No. of hoarding located within 25 mtr"
                    />

                    <TextField
                      fullWidth
                      type="number"
                      value={advertisementDetails.siteLocation.numberOfTreesWithin25m}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            numberOfTreesWithin25m: e.target.value,
                          },
                        }));
                      }}
                      label="No. of trees within 25 mtr. on either side"
                    />
                    <FormLabel id="demo-radio-buttons-group-label">Whether trees are required to cut</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="No"
                      name="radio-buttons-group"
                      value={advertisementDetails.siteLocation.treesCutRequired}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            treesCutRequired: e.target.value,
                          },
                        }));
                      }}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel id="demo-simple-select-label">LandLord Details: </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.landlord.name}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          landlord: {
                            ...prevDetails.landlord,
                            name: e.target.value,
                          },
                        }));
                      }}
                      label="Name of the Landlord"
                    />
                    <FormLabel id="demo-radio-buttons-group-label">NOC of the Landlord submitted</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={advertisementDetails.landlord.nocSubmitted}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          landlord: {
                            ...prevDetails.landlord,
                            nocSubmitted: e.target.value,
                          },
                        }));
                      }}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Whether documents submitted as per rule 17 (b)
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={advertisementDetails.documentsSubmitted}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, documentsSubmitted: e.target.value });
                      }}
                    >
                      <FormControlLabel value="true" control={<Radio />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <FormGroup>
                      <FormControlLabel
                        required
                        control={<Checkbox />}
                        label="I have read carefully the provision of Maharashtra Municipal Council, Nagar Panchayt (regulating and control the display of sky-signs and advertisement) Guidelines, 2023 on above and have complied all the conditions. I accept, that, in the event the information submitted by me is found false, I am liable for all such penal actions as prescribed under the law.
"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Button
              sx={{ width: '150px' }}
              disabled={loading}
              type="submit"
              onClick={submitHandler}
              size="large"
              color="primary"
              variant="contained"
            >
              {loading ? <CircularProgress /> : 'Create'}
            </Button>
          </Box>
        </Card>
      </Container>
    </>
  );
}
