/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
// @mui
import { Stack, Container, Typography, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAllApplicationByType, getAllApplications } from 'src/Services/apiService';
import ApplicationsListData from './applicationsListData';
// components
// mock

export default function ListApplications({ applicationType }) {
  const [getAllapplicationsData, setAllApplications] = useState([]);

  useEffect(() => {
    getApplications();
  }, [applicationType]);

  const getApplications = async () => {
    const getApplicationData = await getAllApplicationByType(applicationType);
    setAllApplications(getApplicationData);
  };

  return (
    <>
      <Helmet>
        <title> List of Application | Smart Genesis </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            List of {applicationType} Application
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item size={12}>
            <ApplicationsListData applicationsData={getAllapplicationsData} applicationType={applicationType} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
