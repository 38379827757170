import React from 'react';
import dayjs from 'dayjs';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
// import nmmtLogo from '../../assets/images/nmmtLogo.png';

// Register Font
// Font.register({
//   family: 'TiroDevanagariMarathi',
//   src: TiroDevanagariMarathi,
// });
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '20px',
    width: '100%',
    fontSize: '14px',
    fontFamily: 'TiroDevanagariMarathi',
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  image: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title: {
    flexDirection: 'row',
    textAlign: 'center',
    fontSize: '22px',
    display: 'flex',
  },
  borderView: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  border: {
    border: '1px solid #9e9e9e',
  },
  table: {
    display: 'table',
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 10,
    width: '100%',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    borderWidth: 1,
  },
  tableCell: {
    margin: 'auto',
    padding: 5,
    flexDirection: 'column',
    fontSize: 12,
    borderWidth: 1,
  },
  checkbox: {
    fontSize: 12,
    marginRight: 5,
  },
  horizontalLine: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    marginVertical: 10,
  },
  signatureSection: {
    marginTop: 20,
  },
  signatureText: {
    fontSize: 12,
  },
});

const ApplicatiomPdf = ({ applicationData }) => {
  console.log('🚀 ~ ApplicationPDFFile ~ applicationData:', applicationData);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Section */}
        {/* <View style={styles.image}>
          <Image src={nmmtLogo} style={{ width: '100px' }} />
        </View> */}
        <View>
          <Text style={styles.title}>Advertising Application Application Form</Text>
        </View>
        <View style={styles.section}>
          <Text>Application Id: {applicationData._id}</Text>
        </View>
        <View style={styles.section}>
          <Text>1. Name of Applicant: {applicationData.applicant.name}</Text>
        </View>
        <View style={styles.section}>
          <Text>2. Name of Agency: {applicationData.applicant.agencyName}</Text>
        </View>
        <View style={styles.section}>
          <Text>3. Status: {applicationData.applicant.status}</Text>
        </View>
        <View style={styles.section}>
          <Text>4. Address: {applicationData.applicant.address}</Text>
        </View>
        <View style={styles.section}>
          <Text>5. City: {applicationData.applicant.city}</Text>
        </View>

        <View style={styles.section}>
          <Text>6. Phone Number: {applicationData.applicant.phoneNumber}</Text>
          {/* Add more fields as needed */}
        </View>
        <View style={styles.section}>
          <Text>7. Email Address: {applicationData.applicant.emailAddress}</Text>
        </View>
        <View style={styles.section}>
          <Text>
            {' '}
            8. Location: <Text> {applicationData.advertisementDetails.location.fullAddress}</Text>
          </Text>
        </View>
        <View style={styles.section}>
          <Text>9. Medium of advertisements applied for: {applicationData.advertisementDetails.medium}</Text>
          {/* Add more fields as needed */}
        </View>
        <View style={styles.section}>
          <Text>10. Form of Advertisement: {applicationData.advertisementDetails.form}</Text>
          {/* Add more fields as needed */}
        </View>
        <View style={styles.section}>
          <Text>11. Nature of Advertisement: {applicationData.advertisementDetails.nature}</Text>
        </View>
        <View style={styles.section}>
          <Text>12. Purpose of Advertisement: {applicationData.advertisementDetails.purpose}</Text>
        </View>
        <View style={styles.section}>
          <Text>13. Text of the message on banne: {applicationData.advertisementDetails.messageText}</Text>
        </View>

        <View style={{ flexDirection: 'row', width: '100%', margin: 5, padding: 5, flexGrow: 1 }}>
          <View style={{ width: '49%', marginRight: '1%' }}>
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <Text>
                14. Period From : {dayjs(applicationData.advertisementDetails.periodFrom).format('DD/MM/YYYY')}
              </Text>
            </View>
          </View>
          <View style={{ width: '49%', marginLeft: '1%' }}>
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <Text> 15. Period To : {dayjs(applicationData.advertisementDetails.periodTo).format('DD/MM/YYYY')}</Text>
            </View>
          </View>
        </View>
        {applicationData.advertisementDetails.locationDetails.length > 0 ? (
          <View style={styles.section}>
            <Text>Advertisement Location Details</Text>
            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>Location</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Number of Banners</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Width</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Height</Text>
                </View>
              </View>

              {/* Table Rows */}
              {applicationData.advertisementDetails.locationDetails.map((locationDetail, index) => (
                <View style={styles.tableRow} key={index}>
                  <View style={styles.tableCell}>
                    <Text>{locationDetail.location}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{locationDetail.numBanners}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{locationDetail.width}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{locationDetail.height}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        ) : (
          <View style={styles.section}>
            <Text>Advertisement Dimensions</Text>
            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>Dimensions</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>In tt/Feet.</Text>
                </View>
              </View>

              {/* Table Rows */}
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>Width: </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{applicationData.advertisementDetails.dimensionsWidth}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>Height: </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{applicationData.advertisementDetails.dimensionsHeight}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>Total Area: </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{applicationData.advertisementDetails.dimensionsTotalArea}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text>Length: </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>{applicationData.advertisementDetails.dimensionsLength}</Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {/* Site Location Section */}
        <View style={styles.section}>
          <Text>10. Site Location: {applicationData.advertisementDetails.location.fullAddress}</Text>
        </View>

        {/* Landlord and NOC Section */}
        <View style={styles.section}>
          <Text>11. Name of the Landlord: {applicationData.advertisementDetails.landlord.name}</Text>
          <Text>
            12. NOC of the Landlord submitted:{' '}
            {applicationData.advertisementDetails.landlord.nocSubmitted ? 'Yes' : 'No'}
          </Text>
          {/* Add more fields as needed */}
        </View>

        {/* Documents Submitted Section */}
        <View style={styles.section}>
          <Text>
            13. Whether documents submitted as per rule 17 (b):{' '}
            {applicationData.advertisementDetails.documentsSubmitted ? 'Yes' : 'No'}
          </Text>
        </View>

        {/* Declaration Section */}
        <View style={styles.section}>
          <Text>
            <Text style={styles.checkbox}>☑️</Text> I have read carefully the provision of Maharashtra Municipal
            Council, Nagar Panchayat (regulating and controlling the display of sky-signs and advertisement) Guidelines,
            2023 on above, and have complied with all the conditions. I accept, that, in the event the information
            submitted by me is found false, I am liable for all such penal actions as prescribed under the law.
          </Text>

          {/* Add more fields as needed */}

          {/* Signature Section */}

          <View style={styles.signatureSection}>
            <Text>
              ___________ <Text>{applicationData.applicant.name}</Text>____________
            </Text>

            <Text>Signature of the Applicant</Text>
            <Text>Date: {dayjs(applicationData.createdAt).format('DD/MM/YYYY MM:HH')}</Text>
          </View>
          <View style={styles.horizontalLine} />
        </View>
      </Page>
    </Document>
  );
};

export default ApplicatiomPdf;
