import axios from 'axios';

import { baseUrl } from '../helpers/baseUrl';

// NEW API WRITTEN

export async function getAllLocationDetails() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}api/application/getAllLocations`);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function updateLocationDataStatus() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.put(`${baseUrl}api/application/updateLocationStatus`);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getAllApplicationByType(applicationType) {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}api/application/getAllApplicationByType/${applicationType}`);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getAllApplications() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}api/application/getAllApplications`);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getLatestApplications() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}api/application/latestApplications`);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function applyForNewPermission(applicationData) {
  try {
    const response = await axios(`${baseUrl}api/application/applyForNewPermission`, {
      method: 'POST',
      data: applicationData,
    });
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function changeApplicationStatus(applicationId, isApproved) {
  try {
    const response = await axios.post(`${baseUrl}api/application/changeApplicationStatus/${applicationId}`, isApproved);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function updateApplicationStatus(applicationId, statusData) {
  try {
    const response = await axios.post(`${baseUrl}api/application/updateApplicationStatus/${applicationId}`, statusData);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getApplicationById(applicationId) {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}api/application/getApplicationById/${applicationId}`);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getLatestDashboardStats() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}api/application/dashboardStats`);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

/// /////////////////////////////////////// ///////////////////////////////////////////
// OLD API STARTED DELETE AFTER USE

export async function getAllDocuments() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}document/getAllDocuments`);
    console.log('getAllDocuments', response);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return [];
  }
}

export async function getDocumentById(documentId) {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}document/getDocumentById/${documentId}`);
    console.log('getDocumentById', response);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return [];
  }
}

export async function getDocumentByWorkId(documentWorkId) {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}document/getDocumentByWorkId:${documentWorkId}`);
    console.log('getDocumentByWorkId', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getVerifiedDocument(documentId) {
  // var tokenStr = localStorage.getItem('token');
  // axios.defaults.headers.common['authorization'] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}document/verifyData/${documentId}`);
    console.log('getVerifiedDocument', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getWhatsappAllCount() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}getallmessageCount?messageId=hi`);
    console.log('getWhatsappAllCount', response);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getWhatsappTableDataAll() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}getallmessage?page=1`);
    console.log('getWhatsappTableData', response);
    return response.data;
  } catch (err) {
    console.log('err', err);
    // return err.response;
    return [];
  }
}

export async function getWhatsappTableDataDaily() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}getDailymsg?messageId=Likemanu123&page=1`);
    console.log('getWhatsappTableDataDaily', response);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    // return err.response;
    return [];
  }
}
export async function getWhatsappTableDataMonth() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}getOneMonthmsg?messageId=Likemanu1234&page=1`);
    console.log('getWhatsappTableDataMonth', response);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    // return err.response;
    return [];
  }
}
export async function getWhatsappTableDataYear() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}getOneYearmsg?page=1&messageId=hii`);
    console.log('getWhatsappTableDataYear', response);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    // return err.response;
    return [];
  }
}

export async function getAllWhatsAppUsers() {
  const tokenStr = localStorage.getItem('token');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}admin/getAllUsers/0`);
    console.log('getAllWhatsAppUsers', response);
    return response.data;
  } catch (err) {
    console.log('err', err.response);
    // return err.response;
    return [];
  }
}

/// Archival//////////////////////////////////////

export async function generateSchema(formObject) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}archival/documentType/generate`, formObject);
    console.log('generateSchema', response);
    return response;
  } catch (err) {
    console.log('err', err);
    return err;
  }
}

export async function getDocumentTypeList(pageNumber) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}archival/documentType/list?page=${pageNumber}`);
    // console.log('getDocumentTypeList', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function updateSchema(formObject) {
  console.log('form', formObject);
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}archival/documentType/schema/update`, formObject);
    console.log('updateSchema', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function deleteSchema(deleteSchemaObj) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.delete(`${baseUrl}archival/delete/schema`, { data: deleteSchemaObj });
    console.log('deleteSchema', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function createDocument(documentObj) {
  console.log('API', documentObj);
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}archival/add/document`, documentObj);
    console.log('createDocument', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getDocumentList(documentType, pageNumber) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}archival/document/list?documentType=${documentType}&page=${pageNumber}`
    );
    console.log('getDocumentList', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
    // return [];
  }
}

export async function updateDocument(documentObj) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}archival/update/document`, documentObj);
    console.log('updateDocument', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function deleteDocument(documentObj) {
  console.log('DPC', documentObj);
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.delete(`${baseUrl}archival/delete/document`, { data: documentObj });
    console.log('deleteDocument', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function getUsersList(pageNumber) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}nmmt/user/list/?page=${pageNumber}`);
    console.log('getUsersList', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function deleteUserById(deleteData) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.delete(`${baseUrl}nmmt/user/delete/`, { data: deleteData });
    console.log('deleteUser', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function updateUser(userData) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}nmmt/user/update`, userData);
    console.log('updateUser', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function searchDoc(searchData) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}archival/document/search/${searchData.searchTerm}?documentType=${searchData.documentType}&page=${searchData.pageNumber}`
    );
    console.log('searchDoc', response);
    return response;
  } catch (err) {
    console.log('err', err);
    return err;
  }
}

export async function searchUser(userData) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}nmmt/user/search/${userData}`);
    console.log('searchUser', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function documentFilter(searchData) {
  const tokenStr = localStorage.getItem('jwtArchivalToken');
  axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}archival/document/filter?documentType=${searchData.documentType}&startDate=${searchData.startDate}&endDate=${searchData.endDate}&uid=${searchData.uid}&page=${searchData.page}`
    );
    console.log('documentFilter', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function extractExcelFileUser(searchData) {
  // const tokenStr = localStorage.getItem('jwtArchivalToken');
  // axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}archival/excel/user/entry/?uid=${searchData.uid}`);
    console.log('extractExcelFileUser', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

export async function extractExcelFileWithDate(searchData) {
  // const tokenStr = localStorage.getItem('jwtArchivalToken');
  // axios.defaults.headers.common.authorization = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}archival/excel/filter/date/entry?startDate=${searchData.startDate}&endDate=${searchData.endDate}&uid=${searchData.uid}`
    );
    console.log('extractExcelFileWithDate', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}
