/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Card, Stack, Button, Container, Typography, Divider, CardContent } from '@mui/material';
// components
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import InputLabel from '@mui/material/InputLabel';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import { signUp } from 'src/Services/userService';
import { UploadMultiFile, UploadSingleFile } from 'src/components/upload';
import validations from '../customValidations/validations';
import validationService from '../customValidations/validationService';

// ----------------------------------------------------------------------

export default function UploadDocuments() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [department, setDepartment] = useState('');
  // const [role, setRole] = useState(localStorage.role);
  const [loading, setLoading] = useState(false);

  // const departmentList = [
  //   'Traffic and Transport',
  //   'Engineering',
  //   'Finance',
  //   'Legal',
  //   'Personnel and Administration',
  //   'Stores and Purchase',
  //   'IT',
  // ];

  const [errorMsg, setErrorMsg] = useState({
    name: { errorMsg: '' },
    email: { errorMsg: '' },
    password: { errorMsg: '' },
    // department: { errorMsg: '' },
  });

  // const handleClick = () => {
  //   navigate('/dashboard', { replace: true });
  // };

  const handleInputChange = (name, value) => {
    if (name === 'name') {
      setName(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'department') {
      setDepartment(value);
    }
  };

  const [age, setAge] = useState('');
  const [medium, setMedium] = useState('');
  const [freeBanner, setFreeBanner] = useState(false);

  useEffect(() => {}, [freeBanner]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value,
        validations: validations.signUpValidations[name],
      },
    ];

    validationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength += 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = '';
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const errorName = customValidations('name', name);
    const errorEmail = customValidations('email', email);
    const errorPassword = customValidations('password', password);
    // let errorDepartment = customValidations('department', department);

    if (errorName !== 1 && errorEmail !== 1 && errorPassword !== 1) {
      console.log('Alll okkkkkkk');
    } else {
      console.log('Validation error');
      return;
    }

    // if (localStorage.role === 'Adminblockchain') {
    //   let errorDepartment = customValidations('department', department);
    //   if (errorDepartment === 1) {
    //     return;
    //   }
    // }
    setLoading(true);

    const userData = {
      name,
      email,
      // department: department,
      password,
    };

    const signUpResponse = await signUp(userData);
    console.log('signUpRes', signUpResponse);
    if (signUpResponse.status === 200) {
      toast.success(`${signUpResponse.data.message}`);
      setLoading(false);
      navigate('/users-list');
    } else if (signUpResponse.status === 401) {
      toast.error(`${signUpResponse.data.message}`);
      setLoading(false);
    } else {
      toast.error(`Something went wrong, please try again`);
      setLoading(false);
    }
  };

  const [fieldValue, setFieldValue] = useState([]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFieldValue(
        'images',
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [setFieldValue]
  );

  const handleRemoveAll = () => {
    setFieldValue('images', []);
  };

  const handleRemove = (file) => {
    const filteredItems = fieldValue.images.filter((_file) => _file !== file);
    setFieldValue('images', filteredItems);
  };

  return (
    <>
      <Helmet>
        <title> Upload Required Documents | Smart Genesis </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Upload Required Documents
          </Typography>
        </Stack>

        <Card>
          {/* <CardHeader subheader="All fields are required" title="Create New Work Order" /> */}
          <Divider />
          <CardContent>
            <div>
              <InputLabel>Add Designs</InputLabel>
              <UploadSingleFile
                showPreview
                maxSize={3145728}
                accept="image/*"
                files={fieldValue}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
              />
            </div>
            <div>
              <InputLabel>No Objection Certificate (NOC) from the landlord </InputLabel>
              <UploadSingleFile
                showPreview
                maxSize={3145728}
                accept="image/*"
                files={fieldValue}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
              />
            </div>
            <div>
              <InputLabel>
                Two copies of proposed hoarding location plan duly attested by the Structural Engineer.{' '}
              </InputLabel>
              <UploadSingleFile
                showPreview
                maxSize={3145728}
                accept="image/*"
                files={fieldValue}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
              />
            </div>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Button
              sx={{ width: '150px' }}
              disabled={loading}
              type="submit"
              onClick={submitHandler}
              size="large"
              color="primary"
              variant="contained"
            >
              {loading ? <CircularProgress /> : 'Create'}
            </Button>
          </Box>
        </Card>
      </Container>
    </>
  );
}
