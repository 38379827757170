import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import 'react-toastify/dist/ReactToastify.css';
import { signUp } from '../../../Services/userService';
import validations from '../../../customValidations/validations';
import validationService from '../../../customValidations/validationService';
// ----------------------------------------------------------------------

export default function SignUpForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setFullName] = useState('');

  const [errorMsg, setErrorMsg] = useState({
    name: { errorMsg: '' },
    email: { errorMsg: '' },
    password: { errorMsg: '' },
  });

  // const handleClick = () => {
  //   navigate('/dashboard', { replace: true });
  // };

  const handleInputChange = (name, value) => {
    if (name === 'name') {
      setFullName(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value,
        validations: validations.signUpValidations[name],
      },
    ];

    validationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength += 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = '';
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleSignUp = async () => {
    const errorFullName = customValidations('name', name);
    const errorEmail = customValidations('email', email);
    const errorPassword = customValidations('password', password);
    // const errorDepartment = customValidations('department', department);

    if (errorFullName !== 1 && errorEmail !== 1 && errorPassword !== 1) {
      console.log('Alll okkkkkkk');
    } else {
      console.log('Validation error');
      return;
    }

    const userData = {
      name,
      email,
      password,
      role: 'user',
    };

    const signUpResponse = await signUp(userData);
    console.log('signUpRes', signUpResponse);
    if (signUpResponse.status === 200) {
      toast.success(`${signUpResponse.data.msg}`);
      navigate('/login', { replace: true });
    } else if (signUpResponse.status === 401) {
      toast.error(`${signUpResponse.data.msg}`);
    }
  };

  return (
    <>
      <Stack spacing={3} sx={{ my: 2 }}>
        <TextField
          name="name"
          label="Full name"
          error={errorMsg.name.errorMsg.length > 0}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          helperText={errorMsg.name.errorMsg}
        />
        <TextField
          name="email"
          label="Email address"
          error={errorMsg.email.errorMsg.length > 0}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          helperText={errorMsg.email.errorMsg}
        />

        {/* <TextField
          fullWidth
          select
          label="Department"
          name="department"
          error={errorMsg.department.errorMsg.length > 0}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          required
          value={department}
          variant="outlined"
          helperText={errorMsg.department.errorMsg}
        >
          {departmentList.map((item, idx) => (
            <MenuItem key={idx} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField> */}

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={errorMsg.password.errorMsg.length > 0}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          helperText={errorMsg.password.errorMsg}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSignUp}>
        Sign Up
      </LoadingButton>
    </>
  );
}
