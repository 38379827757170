/* eslint-disable react/prop-types */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

export default function DimensionDataTable({ dimensionData }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows([
      { id: 1, lastName: dimensionData.dimensionsWidth, firstName: 'Width' },
      { id: 2, lastName: dimensionData.dimensionsLength, firstName: 'Length' },
      { id: 2, lastName: dimensionData.dimensionsHeight, firstName: 'Height' },
      { id: 3, lastName: dimensionData.dimensionsTotalArea, firstName: 'Total Area' },
    ]);
  }, [dimensionData]);

  const columns = [
    {
      field: 'firstName',
      headerName: 'Dimensions',
      width: 150,
    },
    {
      field: 'lastName',
      headerName: 'In tt/Feet.',
      width: 150,
    },
  ];

  return (
    <DataGrid
      autoHeight
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      showColumnVerticalBorder
      showCellVerticalBorder
    />
  );
}
