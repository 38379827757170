/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Divider,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  InputAdornment,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { UploadSingleFile } from 'src/components/upload';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { MuiFileInput } from 'mui-file-input';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
// components
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { getAllLocationDetails, applyForNewPermission, updateLocationDataStatus } from 'src/Services/apiService';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DimensionDataTable from '../DimensionDataTable';

// ----------------------------------------------------------------------

export default function ApplyNewPermission() {
  const navigate = useNavigate();
  const [applicantName, setApplicantName] = useState({
    name: '',
    agencyName: '',
    status: '',
    address: '',
    city: 'Jalgaon',
    phoneNumber: '',
    emailAddress: '',
  });

  const [advertisementDetails, setAdvertisementDetails] = useState({
    medium: '',
    location: '',
    form: 'Temporary',
    nature: '',
    purpose: '',
    periodFrom: dayjs(),
    periodTo: dayjs(),
    totalDays: 1,
    messageText: '',
    dimensionsLength: 0,
    dimensionsWidth: 0,
    dimensionsHeight: 0,
    dimensionsTotalArea: 0,
    siteLocation: {
      roadName: '',
      roadWidth: 0,
      numberOfHoardingWithin25m: 0,
      numberOfTreesWithin25m: 0,
      treesCutRequired: 'No',
    },
    landlord: {
      name: '',
      nocSubmitted: 'No',
    },
    bannerDesign: {
      data: null,
      preview: null,
      fileName: null,
    },
    policeNoc: {
      data: null,
      preview: null,
      fileName: null,
    },
    documentsSubmitted: 'false',
    applicationType: 'JMC',
    privateAdress: '',
    locationDetails: [
      {
        location: '',
        numBanners: '',
        width: '',
        height: '',
      },
    ],
  });

  const [locationData, setLocationData] = useState([]);

  const [loading, setLoading] = useState(false);
  // const [role, setRole] = useState(localStorage.role);
  useEffect(() => {
    updateLocationData();
    getAllLocation();
  }, []);

  const getAllLocation = async () => {
    const locations = await getAllLocationDetails();
    setLocationData(locations);
  };

  const updateLocationData = async () => {
    await updateLocationDataStatus();
  };

  useEffect(() => {}, [advertisementDetails]);

  const handleBannerDesign = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      console.log('🚀 ~ ApplyNewPermission ~ file:', file);
      const imgData = {
        preview: URL.createObjectURL(file),
        data: file,
      };
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.onload = () => {
            if (file.size <= 10485760) {
              imgData.data.imageName = 'bannerDesign';
              setAdvertisementDetails({
                ...advertisementDetails,
                bannerDesign: { data: imgData.data, preview: imgData.preview },
              });
            } else {
              // Image does not meet criteria, show error toast
              toast('Image must be less than 5MB in size.');
              toast(true);
            }
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    [advertisementDetails]
  );

  const handlepoliceNoc = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const imgData = {
        preview: URL.createObjectURL(file),
        data: file,
      };
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.onload = () => {
            if (file.size <= 10485760) {
              imgData.data.imageName = 'policeNoc';
              setAdvertisementDetails({
                ...advertisementDetails,
                policeNoc: { data: imgData.data, preview: imgData.preview },
              });
            } else {
              // Image does not meet criteria, show error toast
              toast('Image must be less than 5MB in size.');
              toast(true);
            }
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    [advertisementDetails]
  );

  const removePoliceNoc = () => {
    setAdvertisementDetails({
      ...advertisementDetails,
      policeNoc: {
        file: null,
        preview: null,
      },
    });
  };

  const removeBannerDesign = () => {
    setAdvertisementDetails({
      ...advertisementDetails,
      bannerDesign: {
        file: null,
        preview: null,
      },
    });
  };

  const submitHandler = async (event) => {
    console.log('🚀 ~ ApplyNewPermission ~ fieldValue:', advertisementDetails);
    setLoading(true);
    event.preventDefault();
    const totalDaysAdv = advertisementDetails.periodTo.diff(advertisementDetails.periodFrom, 'day');
    console.log('🚀 ~ submitHandler ~ totalDaysAdv:', totalDaysAdv);
    if (totalDaysAdv > 0) {
      advertisementDetails.totalDays = totalDaysAdv;
    } else {
      advertisementDetails.totalDays = totalDaysAdv + 1;
    }
    // eslint-disable-next-line prefer-const
    let formDataVal = new FormData();

    formDataVal.append('applicant', JSON.stringify(applicantName));
    formDataVal.append('advertisementDetails', JSON.stringify(advertisementDetails));
    formDataVal.append('files', advertisementDetails.bannerDesign.data);
    formDataVal.append('files', advertisementDetails.policeNoc.data);

    const applyForNewPermissionRes = await applyForNewPermission(formDataVal);
    console.log('🚀 ~ submitHandler ~ applicationData:', applyForNewPermissionRes);
    if (applyForNewPermissionRes._id) {
      setLoading(false);
      toast.success('Application Submitted Successfully');
      navigate('/list-of-application');
    } else {
      setLoading(false);
      toast.error('Application Submission Failed');
    }
  };

  const handleLocationChange = (option, index) => {
    console.log('🚀 ~ handleLocationChange ~ option:', option);
    // const { name, value } = e.target;
    const list = [...advertisementDetails.locationDetails];
    list[index].location = option.fullAddress;
    setAdvertisementDetails({ ...advertisementDetails, locationDetails: list });
  };

  const handleNumBannersChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...advertisementDetails.locationDetails];
    list[index][name] = value;
    setAdvertisementDetails({ ...advertisementDetails, locationDetails: list });
  };

  const handleWidthChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...advertisementDetails.locationDetails];
    list[index][name] = value;
    setAdvertisementDetails({ ...advertisementDetails, locationDetails: list });
  };

  const handleHeightChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...advertisementDetails.locationDetails];
    list[index][name] = value;
    setAdvertisementDetails({ ...advertisementDetails, locationDetails: list });
  };

  const handleAddRow = () => {
    setAdvertisementDetails({
      ...advertisementDetails,
      locationDetails: [
        ...advertisementDetails.locationDetails,
        {
          location: '',
          numBanners: '',
          width: '',
          height: '',
        },
      ],
    });
  };

  const handleDeleteRow = (index) => {
    const list = [...advertisementDetails.locationDetails];
    list.splice(index, 1);
    setAdvertisementDetails({ ...advertisementDetails, locationDetails: list });
  };

  return (
    <>
      <Helmet>
        <title> Apply For JCMC Location Permission | Smart Genesis </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {/* Apply For JMC Location Permission */}
            JCMC स्थान परवानगीसाठी अर्ज करा
          </Typography>
        </Stack>

        <Card>
          {/* <CardHeader subheader="All fields are required" title="Create New Work Order" /> */}
          <Divider />
          <CardContent>
            <form onSubmit={submitHandler.bind()}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    label="अर्जदाराचे नाव"
                    value={applicantName.name}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, name: e.target.value });
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="agencyName"
                    label="एजन्सीचे नाव"
                    value={applicantName.agencyName}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, agencyName: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">अर्जदाराचा प्रकार</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="अर्जदाराचा प्रकार"
                      value={applicantName.status}
                      onChange={(e) => {
                        setApplicantName({ ...applicantName, status: e.target.value });
                      }}
                    >
                      <MenuItem value={'Proprietary firm'}>Proprietary firm</MenuItem>
                      <MenuItem value={'Company'}>Company</MenuItem>
                      <MenuItem value={'Partnership firm'}>Partnership firm</MenuItem>
                      <MenuItem value={'Society'}>Society</MenuItem>
                      <MenuItem value={'Individual'}>Individual</MenuItem>
                      <MenuItem value={'Charitable Trust'}>Charitable Trust</MenuItem>
                      <MenuItem value={'Educational Institute'}>Educational Institute</MenuItem>
                      <MenuItem value={'Government'}>Government</MenuItem>
                      <MenuItem value={'Political Party'}>Political Party</MenuItem>
                      <MenuItem value={'Others'}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.address}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, address: e.target.value });
                    }}
                    label="अर्जदाराचा पत्ता"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.city}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, city: e.target.value });
                    }}
                    label="अर्जदाराचे शहर"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.phoneNumber}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, phoneNumber: e.target.value });
                    }}
                    label="अर्जदाराचा संपर्क क्रमांक"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.emailAddress}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, emailAddress: e.target.value });
                    }}
                    label="अर्जदाराचा ई-मेल Address"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">जाहिरातींचे माध्यम अर्ज</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="जाहिरातींचे माध्यम अर्ज"
                      value={advertisementDetails.medium}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, medium: e.target.value });
                      }}
                    >
                      <MenuItem value={'Illuminated'}>प्रकाशमान</MenuItem>
                      <MenuItem value={'Non Illuminated'}>अप्रकाशित</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item md={6} xs={12}>
                  <Autocomplete
                    options={locationData}
                    autoHighlight
                    getOptionLabel={(option) => option.fullAddress}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        disabled={option.isBooked}
                      >
                        {option.fullAddress} ({option.measurement.width}*{option.measurement.height})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a Location"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, option) => {
                      setAdvertisementDetails({
                        ...advertisementDetails,
                        location: option._id,
                        dimensionsWidth: option.measurement.width,
                        dimensionsHeight: option.measurement.height,
                        dimensionsLength: option.measurement.height,
                        dimensionsTotalArea: option.measurement.width * option.measurement.height,
                      });
                    }}
                  />
                </Grid> */}

                <Grid item md={12} xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Location Details
                  </Typography>
                  {advertisementDetails?.locationDetails.map((location, index) => (
                    <Grid container spacing={3} key={index} mt={1} mb={1}>
                      <Grid item md={5} xs={12}>
                        <Autocomplete
                          options={locationData}
                          autoHighlight
                          getOptionLabel={(option) => option.fullAddress}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                              disabled={option.isBooked}
                            >
                              {option.fullAddress} ({option.measurement.width}*{option.measurement.height})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="एक स्थान निवडा"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                          onChange={(event, option) => {
                            handleLocationChange(option, index);
                            setAdvertisementDetails({
                              ...advertisementDetails,
                              location: option._id,
                              dimensionsWidth: option.measurement.width,
                              dimensionsHeight: option.measurement.height,
                              dimensionsLength: option.measurement.height,
                              dimensionsTotalArea: option.measurement.width * option.measurement.height,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          type="number"
                          label="बॅनरची संख्या"
                          value={location.numBanners}
                          name="numBanners"
                          onChange={(e) => handleNumBannersChange(e, index)}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="बॅनरची रुंदी"
                          value={location.width}
                          name="width"
                          onChange={(e) => handleWidthChange(e, index)}
                          type="number"
                          error={
                            advertisementDetails.dimensionsWidth < advertisementDetails.locationDetails[index].width
                          }
                          helperText={
                            advertisementDetails.dimensionsWidth < advertisementDetails.locationDetails[index].width ? (
                              <p>Width should be less than or equal {advertisementDetails.dimensionsWidth} Ft.</p>
                            ) : null
                          }
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="बॅनरची उंची"
                          value={location.height}
                          name="height"
                          type="number"
                          onChange={(e) => handleHeightChange(e, index)}
                          error={
                            advertisementDetails.dimensionsHeight < advertisementDetails.locationDetails[index].height
                          }
                          helperText={
                            advertisementDetails.dimensionsHeight <
                            advertisementDetails.locationDetails[index].height ? (
                              <p>Height should be less than or equal {advertisementDetails.dimensionsHeight} Ft.</p>
                            ) : null
                          }
                        />
                      </Grid>
                      <Grid item md={1} xs={12}>
                        <IconButton
                          onClick={() => handleDeleteRow(index)}
                          disabled={advertisementDetails.locationDetails.length === 1}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button variant="outlined" color="primary" onClick={handleAddRow}>
                    नवीन स्थान जोडा (Add New Location)
                  </Button>
                </Grid>

                {/* <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Form of Advertisement</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Form of Advertisement"
                      value={advertisementDetails.form}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, form: e.target.value });
                      }}
                    >
                      <MenuItem value={'Temporary'}>Temporary</MenuItem>
                      <MenuItem value={'Permanent'}>Permanent</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={advertisementDetails.nature}
                    onChange={(e) => {
                      setAdvertisementDetails({ ...advertisementDetails, nature: e.target.value });
                    }}
                    label="Nature of advertisement applied"
                  />
                </Grid> */}

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Period From"
                        value={advertisementDetails.periodFrom}
                        onChange={(newValue) => {
                          setAdvertisementDetails({ ...advertisementDetails, periodFrom: newValue });
                        }}
                        format="DD-MM-YYYY"
                        // minDate={dayjs()} // Lock previous dates from today
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Period To"
                        value={advertisementDetails.periodTo}
                        onChange={(newValue) => {
                          setAdvertisementDetails({ ...advertisementDetails, periodTo: newValue });
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs(advertisementDetails.periodFrom).add(3, 'day')} // Lock dates after 3 days from period from
                        minDate={dayjs(advertisementDetails.periodFrom)} // Lock dates before period from
                        error={dayjs(advertisementDetails.periodFrom).isAfter(advertisementDetails.periodTo)}
                        helperText={
                          dayjs(advertisementDetails.periodFrom).isAfter(advertisementDetails.periodTo) ? (
                            <p>Period To should be greater than Period From</p>
                          ) : null
                        }
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.purpose}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, purpose: e.target.value });
                      }}
                      label="बॅनरचा उद्देश"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.messageText}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, messageText: e.target.value });
                      }}
                      label="बॅनरवरील संदेशाचा मजकूर"
                    />
                  </FormControl>
                </Grid>

                {/* <Grid item md={6} xs={12}>
                  <InputLabel id="demo-simple-select-label">Dimensions: </InputLabel>
                  <FormControl fullWidth>
                    <DimensionDataTable dimensionData={advertisementDetails} />
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={6} xs={12}>
                  <InputLabel id="demo-simple-select-label">Name of the Road drawing visibility: </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.siteLocation.roadName}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            roadName: e.target.value,
                          },
                        }));
                      }}
                      label="Name of the Road :"
                    />

                    <TextField
                      fullWidth
                      type="number"
                      value={advertisementDetails.siteLocation.roadWidth}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            roadWidth: e.target.value,
                          },
                        }));
                      }}
                      label="Width of the Road :"
                    />

                    <TextField
                      fullWidth
                      type="number"
                      value={advertisementDetails.siteLocation.numberOfHoardingWithin25m}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            numberOfHoardingWithin25m: e.target.value,
                          },
                        }));
                      }}
                      label="No. of hoarding located within 25 mtr"
                    />

                    <TextField
                      fullWidth
                      type="number"
                      value={advertisementDetails.siteLocation.numberOfTreesWithin25m}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            numberOfTreesWithin25m: e.target.value,
                          },
                        }));
                      }}
                      label="No. of trees within 25 mtr. on either side"
                    />
                    <FormLabel id="demo-radio-buttons-group-label">Whether trees are required to cut</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="No"
                      name="radio-buttons-group"
                      value={advertisementDetails.siteLocation.treesCutRequired}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          siteLocation: {
                            ...prevDetails.siteLocation,
                            treesCutRequired: e.target.value,
                          },
                        }));
                      }}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={6} xs={12}>
                  <InputLabel id="demo-simple-select-label">LandLord Details: </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.landlord.name}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          landlord: {
                            ...prevDetails.landlord,
                            name: e.target.value,
                          },
                        }));
                      }}
                      label="Name of the Landlord"
                    />
                    <FormLabel id="demo-radio-buttons-group-label">NOC of the Landlord submitted</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={advertisementDetails.landlord.nocSubmitted}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          landlord: {
                            ...prevDetails.landlord,
                            nocSubmitted: e.target.value,
                          },
                        }));
                      }}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Whether documents submitted as per rule 17 (b)
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={advertisementDetails.documentsSubmitted}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, documentsSubmitted: e.target.value });
                      }}
                    >
                      <FormControlLabel value="true" control={<Radio />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid> */}

                <Grid item md={6} xs={12}>
                  <InputLabel>बॅनर डिझाइन अपलोड करा (File Type: jpeg,png and File Size: less than 5 mb)</InputLabel>
                  {advertisementDetails.bannerDesign.preview ? (
                    <>
                      <CancelOutlinedIcon onClick={removeBannerDesign} />
                      <img src={advertisementDetails.bannerDesign.preview} width={500} height={220} alt="Profile" />
                    </>
                  ) : (
                    <UploadSingleFile maxSize={5145728} accept="image/*" onDrop={handleBannerDesign} />
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    पोलिस स्टेशन एनओसी अपलोड करा (File Type: jpeg,png and File Size: less than 5 mb)
                  </InputLabel>
                  {advertisementDetails.policeNoc.preview ? (
                    <>
                      <CancelOutlinedIcon onClick={removePoliceNoc} />
                      <img src={advertisementDetails.policeNoc.preview} width={500} height={220} alt="Profile" />
                    </>
                  ) : (
                    <UploadSingleFile maxSize={5145728} accept="image/*" onDrop={handlepoliceNoc} />
                  )}
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Button
              fullWidth
              disabled={loading}
              type="submit"
              onClick={submitHandler}
              size="large"
              color="primary"
              variant="contained"
            >
              {loading ? <CircularProgress /> : 'फॉर्म सबमिट करा'}
            </Button>
          </Box>
        </Card>
      </Container>
    </>
  );
}
