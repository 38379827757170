/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { renderToString } from 'react-dom/server';
import dayjs from 'dayjs';
import { Canvg } from 'canvg';
import { Stack, Container, Typography, Grid, Button } from '@mui/material';
import QRCode from 'react-qr-code';
import { StyleSheet, Font } from '@react-pdf/renderer';
import { getApplicationById } from '../../Services/apiService';
import TiroDevanagariMarathi from '../../assets/Tiro_Devanagari_Marathi/TiroDevanagariMarathi-Regular.ttf';
import { frontEndUrl } from '../../helpers/baseUrl';

// Register Font
Font.register({
  family: 'TiroDevanagariMarathi',
  src: TiroDevanagariMarathi,
});

const svgToDataUri = async (svgString) => {
  try {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const v = Canvg.fromString(context, svgString.trim());
      await v.render();
      const dataUri = canvas.toDataURL('image/png');
      return dataUri;
    }
    return '';
  } catch (error) {
    console.error('Error occured:', error);
    return '';
  }
};

const VerifyPanel = () => {
  const { applicationId } = useParams();
  const [svgDataUri, setSvgDataUri] = useState('');

  const [applicationData, setApplicationData] = useState([]);

  useEffect(() => {
    getApplicationDetails();
  }, [applicationId]);

  const getApplicationDetails = async () => {
    const getApplicationRes = await getApplicationById(applicationId);
    if (getApplicationRes._id) {
      setApplicationData(getApplicationRes);
    }
  };

  useEffect(() => {}, [applicationData]);

  const qrCodeRef = React.createRef();

  useEffect(() => {
    async function convertSvgToDataUri() {
      const svg = <QRCode value={`${frontEndUrl}/verify-license/${applicationData._id}`} ref={qrCodeRef} id="QRCode" />;

      const dataUri = await svgToDataUri(renderToString(svg));
      setSvgDataUri(dataUri);
    }

    convertSvgToDataUri();
  }, [applicationData._id, applicationData.permissionId, qrCodeRef]);

  const downloadQRCode = () => {
    const svg = document.getElementById('QRCode');
    console.log('🚀 ~ downloadQRCode ~ canvas:', svg);
    const svgData = new XMLSerializer().serializeToString(svg);
    const pngFile = `data:image/svg+xml;base64,${btoa(svgData)}`;
    const downloadLink = document.createElement('a');
    downloadLink.download = `qrcode-${applicationData._id}`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
    // img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <>
      <Helmet>
        <title>परवानगी परवाना QR कोड माहिती | Smart Genesis </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            परवानगी परवाना QR कोड माहिती
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          {applicationData._id ? (
            <Grid item size={12}>
              {/* <Typography variant="h6" gutterBottom>
            Download below QR Code
          </Typography> */}

              {applicationData.applicationType === 'JMC' ? (
                <>
                  <Typography variant="h6" gutterBottom>
                    जाहिरात फलक (होर्डींग) प्रदर्शीत करणेबाबतचा परवाना पत्र
                  </Typography>

                  <Typography> बॅनर मालक: {applicationData.applicant.name}</Typography>
                  <Typography>परवाना क्रमांक: {applicationData.permissionId}</Typography>
                  <Typography>अर्ज क्रमांक: {applicationData.applicationId}</Typography>

                  <Typography>
                    {' '}
                    प्रदर्शनाचे ठिकाण: {applicationData.advertisementDetails.location.fullAddress}{' '}
                  </Typography>

                  <Typography>
                    {' '}
                    Period From: {dayjs(applicationData.advertisementDetails.periodFrom).format('DD/MM/YYYY')}{' '}
                  </Typography>
                  <Typography>
                    {' '}
                    Period To: {dayjs(applicationData.advertisementDetails.periodTo).format('DD/MM/YYYY')}{' '}
                  </Typography>

                  <Typography>चलन क्रमांक: {applicationData.advertisementDetails.payment.collectedBy}</Typography>
                  <Typography> QR Code: </Typography>
                  <QRCode value={`${frontEndUrl}/verify-license/${applicationData._id}`} ref={qrCodeRef} id="QRCode" />
                  <br />
                  <Button type="button" onClick={downloadQRCode} variant="contained">
                    {' '}
                    Download QR Code{' '}
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h6" gutterBottom>
                    जाहिरात फलक (होर्डींग) प्रदर्शीत करणेबाबतचा परवाना पत्र
                  </Typography>

                  <Typography>बॅनर मालक: {applicationData.applicant.name}</Typography>
                  <Typography>परवाना क्रमांक: {applicationData.permissionId}</Typography>
                  <Typography>अर्ज क्रमांक: {applicationData.applicationId}</Typography>

                  <Typography> प्रदर्शनाचे ठिकाण: {applicationData.advertisementDetails.privateAdress} </Typography>

                  <Typography>
                    {' '}
                    Period From: {dayjs(applicationData.advertisementDetails.periodFrom).format('DD/MM/YYYY')}{' '}
                  </Typography>
                  <Typography>
                    {' '}
                    Period To: {dayjs(applicationData.advertisementDetails.periodTo).format('DD/MM/YYYY')}{' '}
                  </Typography>

                  <Typography>चलन क्रमांक: {applicationData.advertisementDetails.payment.collectedBy}</Typography>

                  <Typography> QR Code: </Typography>
                  <QRCode value={`${frontEndUrl}/verify-license/${applicationData._id}`} ref={qrCodeRef} id="QRCode" />
                  <br />
                  <Button type="button" onClick={downloadQRCode}>
                    {' '}
                    Download QR Code{' '}
                  </Button>
                </>
              )}
            </Grid>
          ) : (
            <Typography variant="h6" gutterBottom>
              No data found
            </Typography>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default VerifyPanel;
