import React, { useState, useEffect } from 'react';
import { Modal, Paper, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { baseUrl } from '../../helpers/baseUrl';

const DocumentModal = ({ open, onClose, applicationData, onApprove }) => {
  const [approvalData, setApprovalData] = useState({
    applicationNo: applicationData._id || '',
    processingFee: applicationData.advertisementDetails.payment.amount || '',
    paymentStatus: '', // Set based on your logic
    paymentMode: '', // Set based on your logic
    dateOfReceipt: applicationData.createdAt || '',
    collectedBy: '',
    isApproved: true,
  });

  const [imageUrl, setImageUrl] = useState('');
  const [policdNoc, setpolicdNoc] = useState('');

  const [error, setError] = useState(null);

  useEffect(() => {
    setImageUrl(baseUrl + applicationData.bannerDesignPath);
    setpolicdNoc(baseUrl + applicationData.policeNocPath);
  }, [applicationData.bannerDesignPath, applicationData.policeNocPath, imageUrl]);

  const handleApprove = () => {
    // Call the onApprove callback with the approval data
    onApprove(approvalData);
    onClose(); // Close the modal
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{ p: 4, width: 500, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <Typography variant="h6" gutterBottom>
          List of Documents(FOR OFFICE USE ONLY)
        </Typography>
        {/* Input Fields */}
        <Typography variant="h6" gutterBottom>
          BANNER DESIGN
        </Typography>

        {imageUrl && <img crossOrigin="anonymous" width={500} height={220} src={imageUrl} alt="bannerDesign" />}
        <Typography variant="h6" gutterBottom>
          POLICE NOC
        </Typography>
        <img src={policdNoc} crossOrigin="anonymous" width={500} height={220} alt="policeNoc" />
      </Paper>
    </Modal>
  );
};

export default DocumentModal;
