/* eslint-disable react/prop-types */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

export default function DimensionDataTableForPrivate({ dimensionData }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows([
      { id: 0, inttsqft: dimensionData.dimensionsWidth, dimensions: 'रुंदी' },
      { id: 1, inttsqft: dimensionData.dimensionsLength, dimensions: 'लांबी' },
      { id: 2, inttsqft: dimensionData.dimensionsHeight, dimensions: 'उंची' },
      { id: 3, inttsqft: dimensionData.dimensionsTotalArea, dimensions: 'एकूण क्षेत्रफळ' },
    ]);
  }, [dimensionData]);

  const columns = [
    {
      field: 'dimensions',
      headerName: 'मोजमाप (Measurement)',
      width: 150,
    },
    {
      field: 'inttsqft',
      headerName: 'In Feet.',
      width: 150,
      editable: true,
      type: 'number',
    },
  ];

  return (
    <DataGrid
      autoHeight
      // autoPageSize
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      showColumnVerticalBorder
      showCellVerticalBorder
    />
  );
}
