import axios from 'axios';

import { baseUrl } from '../helpers/baseUrl';

// export async function signUp(userData) {
//   try {
//     const response = await axios.post(`${baseUrl}admin/signUp`, userData);
//     console.log('signUpRes', response);
//     return response;
//   } catch (err) {
//     console.log('err', err.response);
//     return err.response;
//   }
// }

export async function signUp(userData) {
  try {
    const response = await axios.post(`${baseUrl}api/users/register`, userData);
    console.log('signUpRes', response);
    return response;
  } catch (err) {
    console.log('err', err.response);
    return err.response;
  }
}

// export async function login(userData) {
//   try {
//     const response = await axios.post(`${baseUrl}admin/login`, userData);
//     console.log('login', response);
//     const { Token, Name, email, department, role, superAdmin } = response.data;

//     localStorage.setItem('jwtBlockChainToken', Token);
//     localStorage.setItem('blockchainName', Name);
//     localStorage.setItem('blockchainEmail', email);
//     localStorage.setItem('department', department);
//     localStorage.setItem('role', role);
//     localStorage.setItem('superAdmin', superAdmin);

//     return response;
//   } catch (err) {
//     console.log('err', err.response);
//     return err.response;
//   }
// }

export async function login(userData) {
  try {
    const response = await axios.post(`${baseUrl}api/users/login`, userData);
    console.log('login', response);
    return response;
  } catch (err) {
    console.log('err', err);
    return err;
  }
}
