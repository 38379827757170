import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import shareFill from '@iconify/icons-eva/share-fill';
import printerFill from '@iconify/icons-eva/printer-fill';
import archiveFill from '@iconify/icons-eva/archive-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
// material
import { useTheme } from '@mui/material/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Menu,
  Card,
  Table,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  CardHeader,
  TableContainer,
  Chip,
} from '@mui/material';
// utils
import { getLatestApplications } from '../../../Services/apiService';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function MoreMenuButton() {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem>
          <Icon icon={downloadFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Download
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={printerFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Print
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={shareFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Share
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={archiveFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Archive
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem sx={{ color: 'error.main' }}>
          <Icon icon={trash2Outline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default function AppNewInvoice() {
  const theme = useTheme();

  const [getLatestapplication, setLatestApplications] = useState([]);

  useEffect(() => {
    getApplications();
  }, []);

  const getApplications = async () => {
    const getApplicationData = await getLatestApplications();
    // const getApplicationData = await getAllApplicationByType('JMC');
    console.log('🚀 ~ getApplications ~ getApplicationData:', getApplicationData);
    setLatestApplications(getApplicationData);
  };

  return (
    <Card>
      <CardHeader title="Latest Applications" sx={{ mb: 3 }} />

      <TableContainer sx={{ minWidth: 720 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Application ID</TableCell>
              <TableCell>Applicant Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {getLatestapplication.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{`${row.applicationId}`}</TableCell>
                <TableCell>{row.applicant.name}</TableCell>
                <TableCell>{row.advertisementDetails.location.fullAddress}</TableCell>
                <TableCell>
                  <Chip
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={
                      (row.applicationStatus === 'Pending For Scrutiny' && 'warning') ||
                      (row.applicationStatus === 'Pending For Officer' && 'info') ||
                      (row.applicationStatus === 'Pending For Approval' && 'primary') ||
                      (row.applicationStatus === 'Pending For Payment' && 'secondary') ||
                      (row.applicationStatus === 'Rejected' && 'error') ||
                      'success'
                    }
                    label={row.applicationStatus}
                  />
                </TableCell>
                <TableCell align="right">
                  <MoreMenuButton />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="/list-of-application"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box>
    </Card>
  );
}
