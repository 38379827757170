// component
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PeopleIcon from '@mui/icons-material/People';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useEffect, useState } from 'react';
import { AddBusinessTwoTone, Upload } from '@mui/icons-material';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// Role -
// AdminWhatappBot
// Adminblockchain

// const navConfig = [
//   {
//     title: 'Generate Schema',
//     path: 'generate-schema',
//     icon: icon('ic_analytics'),
//   },
//   {
//     title: 'Schema List',
//     path: 'schema-list',
//     icon: <DocumentScannerIcon />,
//   },

//   {
//     title: 'Document List',
//     path: 'document-list',
//     icon: <DocumentScannerIcon />,
//   },
// ];
// export default navConfig;

export default function NavConfig() {
  const [role, setRole] = useState(localStorage.archivalRole);
  const [navConfig, setNavConfig] = useState([]);

  useEffect(() => {
    setRole(localStorage.archivalRole);
  }, []);

  useEffect(() => {
    if (role === 'admin' || role === 'DMC' || role === 'officer' || role === 'clerk') {
      setNavConfig([
        {
          title: 'Statistics Dashboard',
          path: 'statistics-dashboard',
          icon: icon('ic_analytics'),
        },
        {
          title: 'Apply For JMC Location',
          path: 'apply-for-jmc-location',
          icon: <NoteAddIcon />,
        },
        {
          title: 'Apply For Private Location',
          path: 'apply-for-private-location',
          icon: <AddBusinessTwoTone />,
        },
        // {
        //   title: 'Upload Document',
        //   path: 'upload-document',
        //   icon: <Upload />,
        // },
        {
          title: 'Apply For Renewal',
          path: 'apply-for-renewal',
          icon: <DocumentScannerIcon />,
        },
        {
          title: 'JMC Applications List',
          path: 'list-of-application',
          icon: <SummarizeIcon />,
        },
        {
          title: 'Private Applications List',
          path: 'list-of-private-application',
          icon: <SummarizeIcon />,
        },
        // {
        //   title: 'Renewal Applications',
        //   path: 'users-list',
        //   icon: <PeopleIcon />,
        // },
        // {
        //   title: 'Settings',
        //   path: 'users-list',
        //   icon: <PeopleIcon />,
        // },
        // {
        //   title: 'Payments Order',
        //   path: 'users-list',
        //   icon: <PeopleIcon />,
        // },
      ]);
    } else if (role === 'user') {
      setNavConfig([
        // {
        //   title: 'Generated Doc List',
        //   path: 'generated-doc-list',
        //   icon: <DocumentScannerIcon />,
        // },
        {
          title: 'Apply For JMC Location',
          path: 'apply-for-jmc-location',
          icon: <NoteAddIcon />,
        },
        {
          title: 'Apply For Private Location',
          path: 'apply-for-private-location',
          icon: <AddBusinessTwoTone />,
        },
      ]);
    }
  }, [role]);

  return { navConfig };
}
