import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import SignUpPage from './pages/SignUpPage';
import StatisticsDashboard from './pages/StatisticsDashboard';
import ApplyNewPermission from './pages/applications/ApplyNewPermission';
import UploadDocuments from './pages/UploadDocument';
import ApplyForRenewal from './pages/ApplyForRenewal';
import ListApplications from './pages/applications/listApplications';
import LicenseQrCodePdf from './pages/applications/licenseQrCodePdf';
import ApplyPrivateLocation from './pages/applications/ApplyNewPrivatePermission';
import EditPrivateApplicationPermission from './pages/applications/EditPrivateApplicationPermission';
import VerifyPanel from './pages/applications/verifyPanel';

// ----------------------------------------------------------------------

export default function Router() {
  const archivalRole = localStorage.getItem('archivalRole');
  const routes = useRoutes([
    {
      // path: '/dashboard',
      element: <DashboardLayout />,
      children:
        archivalRole === 'admin' || archivalRole === 'DMC' || archivalRole === 'officer' || archivalRole === 'clerk'
          ? [
              { path: '/', element: <Navigate to="/statistics-dashboard" /> },
              { path: 'statistics-dashboard', element: <StatisticsDashboard /> },
              { path: 'apply-for-jmc-location', element: <ApplyNewPermission /> },
              { path: 'apply-for-renewal', element: <ApplyForRenewal /> },

              { path: 'upload-document', element: <UploadDocuments /> },
              { path: 'list-of-application', element: <ListApplications applicationType={'JMC'} /> },
              { path: 'view-license-qrcode/:applicationId', element: <LicenseQrCodePdf /> },
              { path: 'list-of-private-application', element: <ListApplications applicationType={'PRIVATE'} /> },
              { path: 'edit-private-application/:applicationId', element: <EditPrivateApplicationPermission /> },

              // { path: 'list-of-application-municipal', element: <ListApplicationsmunicipalloc /> },
              // { path: 'municipal-location', element: <MunicipalLocation /> },
              { path: 'apply-for-private-location', element: <ApplyPrivateLocation /> },
              { path: 'verify-license/:applicationId', element: <VerifyPanel /> },
            ]
          : [{ path: 'verify-license/:applicationId', element: <VerifyPanel /> }],
    },
    { path: '/verify-license/:applicationId', element: <VerifyPanel /> },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/signup',
      element: <SignUpPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/login" /> },
        { path: '/verify-license/:applicationId', element: <VerifyPanel /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/login" replace />,
    },
  ]);

  return routes;
}
