import React, { useState } from 'react';
import { Modal, Paper, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ApprovalModal = ({ open, onClose, applicationData, onApprove }) => {
  const [approvalData, setApprovalData] = useState({
    applicationNo: applicationData._id || '',
    applicationStatus: applicationData.applicationStatus,
    processingFee: applicationData.advertisementDetails.payment.amount || '',
    paymentStatus: '', // Set based on your logic
    paymentMode: '', // Set based on your logic
    dateOfReceipt: applicationData.createdAt || '',
    collectedBy: '',
    isApproved: true,
    remark: '',
  });

  const archivalRole = localStorage.getItem('archivalRole');

  const handleApprove = (e, status) => {
    e.preventDefault();
    // Call the onApprove callback with the approval data
    onApprove(approvalData, status);
    onClose(); // Close the modal
  };

  return (
    <Modal open={open} onClose={onClose}>
      {archivalRole === 'DMC' ? (
        <Paper
          sx={{ p: 4, width: 500, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <Typography variant="h6" gutterBottom>
            Verified and Approve Application for Payment(FOR DMC USE ONLY)
          </Typography>
          {/* Input Fields */}
          <TextField label="Application No" fullWidth disabled value={applicationData.applicationId} margin="normal" />
          <TextField
            label="Remark"
            fullWidth
            onChange={(e) => setApprovalData({ ...approvalData, comment: e.target.value })}
            multiline
            value={approvalData.comment}
            margin="normal"
          />

          {/* Action Buttons */}
          <Button variant="contained" onClick={(e) => handleApprove(e, 'Pending For Payment')} sx={{ mt: 2 }}>
            Approve
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={(e) => handleApprove(e, 'Rejected')}
            // onClick={onClose}
            sx={{ mt: 2, ml: 3 }}
          >
            Reject
          </Button>
        </Paper>
      ) : archivalRole === 'officer' ? (
        applicationData.applicationStatus === 'Pending For Officer' ? (
          <Paper
            sx={{ p: 4, width: 500, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          >
            <Typography variant="h6" gutterBottom>
              Verify and approve Application for DMC(FOR OFFICER USE ONLY)
            </Typography>
            {/* Input Fields */}
            <TextField
              label="Application No"
              fullWidth
              disabled
              value={applicationData.applicationId}
              margin="normal"
            />
            <TextField
              label="Remark"
              onChange={(e) => setApprovalData({ ...approvalData, comment: e.target.value })}
              fullWidth
              multiline
              value={approvalData.comment}
              margin="normal"
              required
            />

            {/* Action Buttons */}
            <Button variant="contained" onClick={(e) => handleApprove(e, 'Pending For DMC')} sx={{ mt: 2 }}>
              Verification Done
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={(e) => handleApprove(e, 'Rejected')}
              sx={{ mt: 2, ml: 3 }}
            >
              Reject
            </Button>
          </Paper>
        ) : (
          <Paper
            sx={{ p: 4, width: 500, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          >
            <Typography variant="h6" gutterBottom>
              Approve Payment Details for Application(FOR OFFICE USE ONLY)
            </Typography>
            <p>
              Whether the application is complete as per rule 28 of the Maharashtra Municipal Council, Nagar Panchayt
              (regulating and control the display of sky-signs and advertisement) Guidelines, 2023
            </p>
            {/* Input Fields */}
            <TextField
              label="Application No"
              fullWidth
              disabled
              value={applicationData.applicationId}
              margin="normal"
            />
            <TextField
              label="Processing Fee"
              fullWidth
              // disabled
              name="processingFee"
              value={approvalData.processingFee}
              onChange={(e) => setApprovalData({ ...approvalData, processingFee: e.target.value })}
              margin="normal"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="payment-status">Payment Status</InputLabel>
              <Select
                value={approvalData.paymentStatus}
                onChange={(e) => setApprovalData({ ...approvalData, paymentStatus: e.target.value })}
                inputProps={{ id: 'payment-status' }}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="payment-mode">Payment Mode</InputLabel>
              <Select
                value={approvalData.paymentMode}
                onChange={(e) => setApprovalData({ ...approvalData, paymentMode: e.target.value })}
                inputProps={{ id: 'payment-mode' }}
              >
                <MenuItem value="Online">Online</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="NotPaid">Not Paid</MenuItem>
              </Select>
            </FormControl>

            {/* <TextField label="Date of Receipt"  value={applicationData.dateOfReceipt} margin="normal" /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Receipt"
                fullWidth
                value={applicationData.dateOfReceipt}
                onChange={(newValue) => {
                  setApprovalData({ ...approvalData, dateOfReceipt: newValue });
                }}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
            <TextField
              label="Receipt Number"
              fullWidth
              value={approvalData.collectedBy}
              onChange={(e) => setApprovalData({ ...approvalData, collectedBy: e.target.value })}
              margin="normal"
            />

            {/* Action Buttons */}
            <Button variant="contained" onClick={handleApprove} sx={{ mt: 2 }}>
              Approve
            </Button>
            <Button variant="contained" color="error" onClick={onClose} sx={{ mt: 2, ml: 3 }}>
              Cancel
            </Button>
          </Paper>
        )
      ) : applicationData.applicationStatus === 'Pending For Scrutiny' ? (
        <Paper
          sx={{ p: 4, width: 500, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <Typography variant="h6" gutterBottom>
            Scrutiny Application for Officer(FOR clerk USE ONLY)
          </Typography>
          {/* Input Fields */}
          <TextField label="Application No" fullWidth disabled value={applicationData.applicationId} margin="normal" />
          <TextField
            label="Remark"
            onChange={(e) => setApprovalData({ ...approvalData, comment: e.target.value })}
            fullWidth
            multiline
            value={approvalData.comment}
            margin="normal"
          />

          {/* Action Buttons */}
          <Button variant="contained" onClick={(e) => handleApprove(e, 'Pending For Officer')} sx={{ mt: 2 }}>
            Scrutiny Done
          </Button>
          {/* <Button variant="contained" color="error" onClick={onClose} sx={{ mt: 2, ml: 3 }}>
            Reject
          </Button> */}
        </Paper>
      ) : (
        <Paper
          sx={{ p: 4, width: 500, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <Typography variant="h6" gutterBottom>
            Yo do not have permission to change the status of the application.
          </Typography>

          <Button variant="contained" color="error" onClick={(e) => onClose(e)} sx={{ mt: 2, ml: 3 }}>
            Okay and Close
          </Button>
        </Paper>
      )}
    </Modal>
  );
};

export default ApprovalModal;
