/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Chip, Link, Box, Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { PDFViewer } from '@react-pdf/renderer';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import PDFDocument from './pdfDocument';
import { changeApplicationStatus, updateApplicationStatus } from '../../Services/apiService';
import Iconify from '../../components/iconify';
import ApprovalModal from './approvalModal';
import DocumentModal from './documentsListModal';
import CommentModalView from './commentModal';

const ApplicationsListData = ({ applicationsData, applicationType }) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [applicationId, setApplicationId] = useState('');
  const [applicationStatus, setApplicationStatus] = useState('');

  const [open, setOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [pdfViewItem, setPdfViewItem] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [applicationRes, setApplicationRes] = useState(null);
  const [singleApplicationData, setApplicationData] = useState([]);

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleOpenMenu = (applicationSingleData) => {
    setApplicationId(applicationSingleData._id);
    setApplicationData(applicationSingleData);
    setOpen(true);
  };

  const handleShowPreview = (valuePreview, applicationId) => {
    setOpen(false);
    console.log('🚀 ~ ApplicationsListData ~ showPreview111:', showPreview, valuePreview);
    if (valuePreview === false) {
      setShowPreview(false);
    } else {
      const filteredItems = applicationsData.filter((item) => item._id === applicationId);
      setPdfViewItem(filteredItems[0]);
      setShowPreview(true);
    }
  };

  const columns = [
    { field: 'applicationId', headerName: 'Application ID', width: 200 },
    {
      field: 'applicant.name',
      headerName: 'Applicant name',
      width: 120,
      valueGetter: (params) => `${params.row.applicant.name}`,
    },
    // { field: 'city', headerName: 'City', valueGetter: (params) => `${params.row.applicant.city}` },
    {
      field: 'location',
      headerName: 'Location',
      valueGetter: (params) =>
        params.row.applicationType === 'PRIVATE'
          ? `${params.row.advertisementDetails.privateAdress}`
          : `${params.row.advertisementDetails.location.fullAddress}`,
      width: 200,
    },
    {
      field: 'dimensions',
      headerName: 'Dimensions',
      valueGetter: (params) =>
        `${params.row.advertisementDetails.dimensionsWidth} * ${params.row.advertisementDetails.dimensionsHeight}`,
    },
    {
      field: 'applicationStatus',
      headerName: 'Application Status',
      width: 180,
      renderCell: (params) => (
        // params.row.applicationStatus === 'Approved' ? params.row.permissionId : params.row.applicationStatus,
        <Chip
          // variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (params.row.applicationStatus === 'Pending For Scrutiny' && 'warning') ||
            (params.row.applicationStatus === 'Pending For Officer' && 'info') ||
            (params.row.applicationStatus === 'Pending For Approval' && 'primary') ||
            (params.row.applicationStatus === 'Pending For Payment' && 'secondary') ||
            (params.row.applicationStatus === 'Pending For DMC' && 'info') ||
            (params.row.applicationStatus === 'Rejected' && 'error') ||
            'success'
          }
          label={params.row.applicationStatus}
        />
      ),
    },
    {
      field: 'periodFrom',
      headerName: 'Period From',
      valueGetter: (params) => `${dayjs(params.row.advertisementDetails.periodFrom).format('DD/MM/YYYY')}`,
    },
    {
      field: 'periodTo',
      headerName: 'Period To',
      valueGetter: (params) => `${dayjs(params.row.advertisementDetails.periodTo).format('DD/MM/YYYY')}`,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => `Rs. ${params.row.advertisementDetails.payment.amount}`,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      renderCell: (params) =>
        params.row.advertisementDetails.payment.paymentStatus === true ? (
          <Chip color="success" label="Success" />
        ) : (
          <Chip color="warning" label="Pending" />
        ),
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (params) => (
        <>
          <IconButton
            size="large"
            color="inherit"
            onClick={() => {
              handleOpenMenu(params.row);
            }}
          >
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    setRows(applicationsData);
  }, [applicationsData, applicationStatus, showPreview]);

  useEffect(() => {}, [showPreview]);

  const handleOpenModal = async (applicationId, isApproved, modalName) => {
    setOpen(false);
    const filteredItems = applicationsData.filter((item) => item._id === applicationId);
    setApplicationRes(filteredItems[0]);
    if (!isApproved) {
      const approvalData = {
        applicationNo: applicationId,
        isApproved: false,
        paymentMode: null,
        paymentStatus: null,
        collectedBy: null,
      };
      await handleApprove(approvalData);
      return;
    }
    if (modalName === 'approve') {
      setModalOpen(isApproved);
    } else if (modalName === 'documents') {
      setIsDocumentModalOpen(true);
    } else if (modalName === 'comment') {
      setCommentModal(true);
    }
  };

  const openCommentModal = (e) => {
    setCommentModal(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setModalOpen(false);
    setIsDocumentModalOpen(false);
    setCommentModal(false);
  };

  const handleApprove = async (approvalData, status) => {
    // Handle the approval logic, e.g., send data to the server
    console.log('🚀 ~ handleApprove ~ approvalData:', approvalData);

    if (approvalData.applicationStatus === 'Pending For Payment') {
      const isApprovedData = {
        isApproved: approvalData.isApproved,
        paymentMode: approvalData.paymentMode,
        paymentStatus: approvalData.paymentStatus,
        collectedBy: approvalData.collectedBy,
        processingFee: approvalData.processingFee,
        dateOfReceipt: approvalData.dateOfReceipt,
        comment: approvalData.comment,
        status,
      };
      const changeStatus = await changeApplicationStatus(approvalData.applicationNo, isApprovedData);
      console.log('🚀 ~ approveApplication ~ changeStatus:', changeStatus);
      if (changeStatus.status === 200) {
        setApplicationStatus(changeStatus.data.applicationStatus);
        if (changeStatus.data.applicationStatus === 'Approved') {
          Swal.fire({
            icon: 'success',
            title: 'Congratulations!',
            text: `License No: ${changeStatus.data.permissionId}`,
          });
          toast.success('Application Approved Successfully');
          setModalOpen(false);
        } else {
          toast.success('Application Rejected');
          setModalOpen(false);
        }
      } else {
        toast.error('Application Approved Failed');
        setModalOpen(false);
      }
      // Close the modal
    } else {
      const isApprovedData = {
        isApproved: status !== 'Rejected',
        comment: approvalData.comment,
        status,
        rejectionReason: approvalData.comment,
      };
      const changeStatus = await updateApplicationStatus(approvalData.applicationNo, isApprovedData);
      console.log('🚀 ~ approveApplication ~ changeStatus:', changeStatus);
      if (changeStatus.status === 200) {
        setApplicationStatus(changeStatus.data.applicationStatus);
        if (changeStatus.data) {
          // Swal.fire({
          //   icon: 'success',
          //   title: 'Congratulations!',
          // });
          toast.success(`Application ${status} Successfully`);
          setModalOpen(false);
          if (applicationType === 'PRIVATE') {
            setTimeout(() => {
              navigate('/list-of-private-application');
            }, 500);
          } else {
            setTimeout(() => {
              navigate('/list-of-application');
            }, 1000);
          }
        } else {
          toast.success('Application Rejected');
          setModalOpen(false);
        }
      } else {
        toast.error('Application Approved Failed');
        setModalOpen(false);
      }
    }
  };

  return (
    <>
      <div>
        {showPreview ? (
          <>
            <Button
              onClick={() => handleShowPreview(false, applicationId)}
              size="large"
              color="primary"
              variant="contained"
            >
              Close Document
            </Button>

            <PDFViewer style={{ height: '900px', width: '200%' }}>
              <PDFDocument applicationData={pdfViewItem} />
            </PDFViewer>
          </>
        ) : (
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
            slots={{
              toolbar: GridToolbar,
            }}
          />
        )}
      </div>
      <Popover
        open={Boolean(open)}
        anchorEl={() => open}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 300, left: 750 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {applicationType === 'PRIVATE' ? (
          <Link href={`edit-private-application/${singleApplicationData._id}`}>
            <MenuItem>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Edit Application
            </MenuItem>
          </Link>
        ) : (
          <MenuItem
          // onClick={() => {
          //   navigate(`edit-private-application/${applicationId}`);
          // }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit {applicationType} Application
          </MenuItem>
        )}

        {singleApplicationData.applicationStatus === 'Approved' &&
        singleApplicationData.advertisementDetails.payment.paymentStatus === true ? (
          ''
        ) : (
          <MenuItem onClick={() => handleOpenModal(applicationId, true, 'approve')}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Approve or Reject
          </MenuItem>
        )}

        {/* <MenuItem sx={{ color: 'error.main' }} onClick={() => handleOpenModal(applicationId, false)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Reject
        </MenuItem> */}

        <MenuItem onClick={() => handleOpenModal(applicationId, true, 'documents')}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          View Documents
        </MenuItem>

        <MenuItem onClick={() => handleShowPreview(true, applicationId)}>
          <Iconify icon={'eva:eye-outline'} sx={{ mr: 2 }} />
          View Application
        </MenuItem>

        <MenuItem onClick={() => handleOpenModal(applicationId, true, 'comment')}>
          <Iconify icon={'eva:message-circle-outline'} sx={{ mr: 2 }} />
          Remark and Comments
        </MenuItem>

        <Link href={`view-license-qrcode/${singleApplicationData._id}`}>
          <MenuItem>
            <Box
              display={
                singleApplicationData.applicationStatus === 'Approved' &&
                singleApplicationData.advertisementDetails.payment.paymentStatus === true
                  ? 'block'
                  : 'none'
              }
            >
              <Iconify icon={'eva:eye-outline'} sx={{ mr: 2 }} />
              View QR License
            </Box>
          </MenuItem>
        </Link>
      </Popover>
      {isModalOpen ? (
        <ApprovalModal
          open={isModalOpen}
          onClose={handleCloseModal}
          applicationData={applicationRes}
          onApprove={handleApprove}
        />
      ) : (
        ''
      )}
      {isDocumentModalOpen ? (
        <DocumentModal
          open={isDocumentModalOpen}
          onClose={handleCloseModal}
          applicationData={applicationRes}
          onApprove={handleApprove}
        />
      ) : (
        ''
      )}
      {commentModal ? (
        <CommentModalView open={commentModal} onClose={handleCloseModal} remark={applicationRes?.remark} />
      ) : (
        ''
      )}
    </>
  );
};

export default ApplicationsListData;
