export default {
  loginValidations: {
    name: [
      {
        validation: 'notEmpty',
        message: 'Name is required',
      },
      {
        validation: 'minLength',
        minLength: 4,
        message: 'Minimum 4 characters required',
      },
      {
        validation: 'maxLength',
        maxLength: 30,
        message: 'Maximum 30 characters',
      },
      // {
      //   validation: "regex",
      //   regex: /^[a-z0-9_]*$/,
      //   message:
      //     "Only lowercase (a-z), digits (0-9), and underscores are allowed",
      // },
    ],
    email: [
      {
        validation: 'notEmpty',
        message: 'Email is required',
      },
      {
        validation: 'email',
        message: 'Email is not valid',
      },
    ],
    password: [
      {
        validation: 'notEmpty',
        message: "Can't be empty",
      },
      {
        validation: 'minLength',
        minLength: 8,
        message: 'Minimum 8 characters required',
      },
      {
        validation: 'maxLength',
        maxLength: 30,
        message: 'Maximum 30 characters',
      },
      {
        validation: 'regex',
        regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,}$/,
        message: '1 upper case & 1 lower case & 1 number & special char required',
      },
    ],
  },

  signUpValidations: {
    name: [
      {
        validation: 'notEmpty',
        message: 'Name is required',
      },
      {
        validation: 'minLength',
        minLength: 4,
        message: 'Minimum 4 characters required',
      },
      {
        validation: 'maxLength',
        maxLength: 30,
        message: 'Maximum 30 characters',
      },
      // {
      //   validation: "regex",
      //   regex: /^[a-z0-9_]*$/,
      //   message:
      //     "Only lowercase (a-z), digits (0-9), and underscores are allowed",
      // },
    ],
    email: [
      {
        validation: 'notEmpty',
        message: 'Email is required',
      },
      {
        validation: 'email',
        message: 'Email is not valid',
      },
    ],
    department: [
      {
        validation: 'notEmpty',
        message: 'Email is required',
      },
    ],
    password: [
      {
        validation: 'notEmpty',
        message: "Can't be empty",
      },
      {
        validation: 'minLength',
        minLength: 8,
        message: 'Minimum 8 characters required',
      },
      {
        validation: 'maxLength',
        maxLength: 30,
        message: 'Maximum 30 characters',
      },
      {
        validation: 'regex',
        regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,}$/,
        message: '1 upper case & 1 lower case & 1 number & special char required',
      },
    ],
    confirmPassword: [
      {
        validation: 'notEmpty',
        message: "Can't be empty",
      },
    ],
  },

  uploadValidations: {
    workName: [
      {
        validation: 'notEmpty',
        message: ' Work Name is required',
      },
    ],
    workOrderNumber: [
      {
        validation: 'notEmpty',
        message: ' Work order number is required',
      },
    ],
    department: [
      {
        validation: 'notEmpty',
        message: 'Department is required',
      },
    ],
    workType: [
      {
        validation: 'notEmpty',
        message: 'Work type is required',
      },
    ],
    workPeriod: [
      {
        validation: 'notEmpty',
        message: 'Work period is required',
      },
    ],
    issuedTo: [
      {
        validation: 'notEmpty',
        message: "Can't be empty",
      },
    ],
    document: [
      {
        validation: 'notEmpty',
        message: "Can't be empty",
      },
      {
        validation: 'fileExtension',
        value: 'pdf',
        message: 'File type not allowed, only PDF is allowed',
      },
    ],
  },
};
