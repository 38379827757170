/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet-async';

// @mui
import { Grid, Container, Box, CircularProgress, Card, Typography, Stack } from '@mui/material';

// components
// sections
import { useState, useEffect } from 'react';

import AppNewInvoice from 'src/sections/@dashboard/app/AppNewInvoice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getLatestDashboardStats } from '../Services/apiService';
// utils
import { fNumber } from '../utils/formatNumber';
import totalApplication from '../assets/images/totalApplication.png';
import scrutiny from '../assets/images/scrutiny.png';
import dmc from '../assets/images/womanOfficer.png';
import officer from '../assets/images/clerk.png';
import payment from '../assets/images/payment.png';
import approved from '../assets/images/approved.png';
import reject from '../assets/images/reject.png';
import revenue from '../assets/images/revenue.png';

// ----------------------------------------------------------------------

export default function StatisticsDashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [dashboardStats, setLatestDashboardStats] = useState({
    statusCounts: {
      'Pending For Scrutiny': 0,
      'Pending For Officer': 0,
      'Pending For DMC': 0,
      'Pending For Payment': 0,
      'Payment Done': 0,
      Approved: 0,
      Rejected: 0,
    },
    totalAmount: 0,
    totalApplicationCount: 0,
  });

  useEffect(() => {
    getDashboardStats();
  }, []);

  const getDashboardStats = async () => {
    const getApplicationData = await getLatestDashboardStats();
    console.log('🚀 ~ getDashboardStats ~ getApplicationData:', getApplicationData);
    if (getApplicationData.data?.status === 'fail') {
      toast.error('Session Expired');
      setLoading(false);
      navigate('/login');
    } else {
      setLatestDashboardStats(getApplicationData);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> Statistics Dashboard | Smart Genesis </title>
      </Helmet>

      <Container maxWidth="xl">
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '150px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={3} marginBottom={'2rem'}>
              {/* {documentTypeList.map((document, idx) => ( */}
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Total Applications</Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <Typography variant="h3">{fNumber(dashboardStats.totalApplicationCount)} </Typography>
                      <img src={totalApplication} width={32} alt="" />
                    </Stack>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Pending For Scrutiny</Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <Typography variant="h3">
                        {fNumber(dashboardStats.statusCounts['Pending For Scrutiny'])}
                      </Typography>
                      <img src={scrutiny} alt="" />
                    </Stack>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Pending For Officer</Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <Typography variant="h3">
                        {fNumber(dashboardStats.statusCounts['Pending For Officer'])}
                      </Typography>
                      <img src={officer} alt="" />
                    </Stack>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Pending For DMC</Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <Typography variant="h3">{fNumber(dashboardStats.statusCounts['Pending For DMC'])}</Typography>
                      <img src={dmc} alt="" />
                    </Stack>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Pending For Payment</Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <Typography variant="h3">
                        {fNumber(dashboardStats.statusCounts['Pending For Payment'])}
                      </Typography>
                      <img src={payment} alt="" />
                    </Stack>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Approved Application</Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <Typography variant="h3">{fNumber(dashboardStats.statusCounts.Approved)}</Typography>
                      <img src={approved} alt="" />
                    </Stack>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Rejected Application</Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <Typography variant="h3">{fNumber(dashboardStats.statusCounts.Rejected)}</Typography>
                      <img src={reject} alt="" />
                    </Stack>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">Total Revenue</Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-around"
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <Typography variant="h3">Rs. {fNumber(dashboardStats.totalAmount)}</Typography>
                      <img src={revenue} alt="" />
                    </Stack>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} lg={12}>
                {/* LATEST APPLICATION TABLE */}
                <AppNewInvoice />
              </Grid>
              {/* ))} */}
            </Grid>
          </>
        )}
        {/* <Box sx={{ textAlign: 'center' }}>
          <Box display="flex" justifyContent="center">
            <Button disabled={pageNumber <= 1} size="large" onClick={() => handlePages(pageNumber - 1)}>
              Previous
            </Button>
            <Button
              disabled={pageNumber === pages || pages < 1 || loading}
              size="large"
              onClick={() => handlePages(pageNumber + 1)}
            >
              Next
            </Button>
          </Box>
          <Typography>{rangeLabel}</Typography>
        </Box> */}
      </Container>
    </>
  );
}
