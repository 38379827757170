/* eslint-disable import/no-unresolved */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Divider,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  OutlinedInput,
  Chip,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTheme } from '@mui/material/styles';
import { UploadSingleFile } from 'src/components/upload';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { MuiFileInput } from 'mui-file-input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import dayjs from 'dayjs';
// components
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { applyForNewPermission } from 'src/Services/apiService';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DimensionDataTableForPrivate from '../DimensionDataTableForPrivate';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Banner Designs',
  'NOC From Police Station',
  'Structural Stability Certificate',
  'Accident Liability Insurance',
  'ADHAR/UID ID Verification',
  'NOC From Landlord',
  'MAP of Location',
  'Photos of Advertisement Location',
  'TAX Certificate',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function ApplyPrivateLocation() {
  const navigate = useNavigate();
  const [personName, setPersonName] = React.useState([]);

  const [applicantName, setApplicantName] = useState({
    name: '',
    agencyName: '',
    status: '',
    address: '',
    city: 'Jalgaon',
    phoneNumber: '',
    emailAddress: '',
  });

  const [advertisementDetails, setAdvertisementDetails] = useState({
    medium: '',
    location: '665c843931dc6e995eda4b94',
    privateAdress: '',
    form: '',
    nature: '',
    purpose: '',
    periodFrom: dayjs(),
    periodTo: dayjs(),
    totalDays: 1,
    messageText: '',
    dimensionsLength: 0,
    dimensionsWidth: 0,
    dimensionsHeight: 0,
    dimensionsTotalArea: 0,
    hoardingPlace: 'Ground',
    siteLocation: {
      roadName: '',
      roadWidth: 0,
      numberOfHoardingWithin25m: 0,
      numberOfTreesWithin25m: 0,
      treesCutRequired: 'No',
    },
    landlord: {
      name: '',
      nocSubmitted: 'No',
    },
    bannerDesign: {
      data: null,
      preview: null,
      fileName: null,
    },
    policeNoc: {
      data: null,
      preview: null,
      fileName: null,
    },
    applicationType: 'PRIVATE',
    stabilityIssueDate: dayjs(),
    stabilityValidTill: dayjs(),
    engineerName: '',
    documentsSubmitted: 'false',
    requiredDocuments: personName,
  });

  const [loading, setLoading] = useState(false);
  // const [role, setRole] = useState(localStorage.role);

  useEffect(() => {}, [advertisementDetails]);

  const handleBannerDesign = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      console.log('🚀 ~ ApplyNewPermission ~ file:', file);
      const imgData = {
        preview: URL.createObjectURL(file),
        data: file,
      };
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.onload = () => {
            if (file.size <= 10485760) {
              imgData.data.imageName = 'bannerDesign';
              setAdvertisementDetails({
                ...advertisementDetails,
                bannerDesign: { data: imgData.data, preview: imgData.preview },
              });
            } else {
              // Image does not meet criteria, show error toast
              toast('File must be less than 5MB in size.');
              toast(true);
            }
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    [advertisementDetails]
  );

  // const handlepoliceNoc = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];
  //     const imgData = {
  //       preview: URL.createObjectURL(file),
  //       data: file,
  //     };
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         const img = new Image();
  //         img.onload = () => {
  //           if (file.size <= 10485760) {
  //             imgData.data.imageName = 'policeNoc';
  //             setAdvertisementDetails({
  //               ...advertisementDetails,
  //               policeNoc: { data: imgData.data, preview: imgData.preview },
  //             });
  //           } else {
  //             // Image does not meet criteria, show error toast
  //             toast('Image must be less than 5MB in size.');
  //             toast(true);
  //           }
  //         };
  //         img.src = reader.result;
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   },
  //   [advertisementDetails]
  // );

  // const removePoliceNoc = () => {
  //   setAdvertisementDetails({
  //     ...advertisementDetails,
  //     policeNoc: {
  //       file: null,
  //       preview: null,
  //     },
  //   });
  // };

  const removeBannerDesign = () => {
    setAdvertisementDetails({
      ...advertisementDetails,
      bannerDesign: {
        file: null,
        preview: null,
      },
    });
  };

  const submitHandler = async (event) => {
    console.log('🚀 ~ ApplyNewPermission ~ fieldValue:', advertisementDetails);
    event.preventDefault();
    // setLoading(true);
    const totalDaysAdv = advertisementDetails.periodTo.diff(advertisementDetails.periodFrom, 'day');
    console.log('🚀 ~ submitHandler ~ totalDaysAdv:', totalDaysAdv);
    if (totalDaysAdv > 0) {
      advertisementDetails.totalDays = totalDaysAdv;
    } else {
      advertisementDetails.totalDays = totalDaysAdv + 1;
    }
    // eslint-disable-next-line prefer-const
    let formDataVal = new FormData();

    formDataVal.append('applicant', JSON.stringify(applicantName));
    formDataVal.append('applicationType', advertisementDetails.applicationType);
    formDataVal.append('advertisementDetails', JSON.stringify(advertisementDetails));
    formDataVal.append('files', advertisementDetails.bannerDesign.data);
    formDataVal.append('files', advertisementDetails.policeNoc.data);

    const applyForNewPermissionRes = await applyForNewPermission(formDataVal);
    if (applyForNewPermissionRes._id) {
      setLoading(false);
      toast.success('Application Submitted Successfully');
      navigate('/list-of-application');
    } else {
      setLoading(false);
      toast.error('Application Submission Failed');
    }
  };

  // eslint-disable-next-line no-undef
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <>
      <Helmet>
        <title> Apply For new Private Permission | Smart Genesis </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            खाजगी स्थानासाठी अर्ज करा
          </Typography>
        </Stack>

        <Card>
          {/* <CardHeader subheader="All fields are required" title="Create New Work Order" /> */}
          <Divider />
          <CardContent>
            <form onSubmit={submitHandler.bind()}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    label="अर्जदाराचे नाव"
                    value={applicantName.name}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, name: e.target.value });
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="agencyName"
                    label="एजन्सीचे नाव"
                    value={applicantName.agencyName}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, agencyName: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">एजन्सीचे प्रकार</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Status"
                      value={applicantName.status}
                      onChange={(e) => {
                        setApplicantName({ ...applicantName, status: e.target.value });
                      }}
                    >
                      <MenuItem value={'Proprietary firm'}>Proprietary firm</MenuItem>
                      <MenuItem value={'Company'}>Company</MenuItem>
                      <MenuItem value={'Charitable Trust'}>Charitable Trust</MenuItem>
                      <MenuItem value={'Others'}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.address}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, address: e.target.value });
                    }}
                    label="अर्जदाराचा पत्ता"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.city}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, city: e.target.value });
                    }}
                    label="अर्जदाराचा शहर"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.phoneNumber}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, phoneNumber: e.target.value });
                    }}
                    label="अर्जदाराचा फोन नंबर"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={applicantName.emailAddress}
                    onChange={(e) => {
                      setApplicantName({ ...applicantName, emailAddress: e.target.value });
                    }}
                    label="अर्जदाराचा email address"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">जाहिरातींचे माध्यम अर्ज केले</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Medium of advertisements applied for"
                      value={advertisementDetails.medium}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, medium: e.target.value });
                      }}
                    >
                      <MenuItem value={'Illuminated'}>प्रकाशमान</MenuItem>
                      <MenuItem value={'Non Illuminated'}>अप्रकाशित</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="जाहिरात पूर्ण पत्ता"
                    value={advertisementDetails.privateAdress}
                    onChange={(e) => {
                      setAdvertisementDetails({ ...advertisementDetails, privateAdress: e.target.value });
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">जाहिरातीचे स्वरूप</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Form of Advertisement"
                      value={advertisementDetails.form}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, form: e.target.value });
                      }}
                    >
                      <MenuItem value={'Temporary'}>Temporary</MenuItem>
                      <MenuItem value={'Permanent'}>Permanent</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    value={advertisementDetails.nature}
                    onChange={(e) => {
                      setAdvertisementDetails({ ...advertisementDetails, nature: e.target.value });
                    }}
                    label="जाहिरातीचे स्वरूप लागू केले"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.purpose}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, purpose: e.target.value });
                      }}
                      label="जाहिरात उद्देश"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="जाहिरात कालावधी पासून"
                        value={advertisementDetails.periodFrom}
                        onChange={(newValue) => {
                          setAdvertisementDetails({ ...advertisementDetails, periodFrom: newValue });
                        }}
                        format="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="जाहिरात कालावधी पर्यंत"
                        value={advertisementDetails.periodTo}
                        onChange={(newValue) => {
                          setAdvertisementDetails({ ...advertisementDetails, periodTo: newValue });
                        }}
                        format="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.messageText}
                      onChange={(e) => {
                        setAdvertisementDetails({ ...advertisementDetails, messageText: e.target.value });
                      }}
                      label="बॅनरवरील संदेशाचा मजकूर(Message Text on Banner)"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Hoarding Place</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Hoarding Place"
                      value={applicantName.hoardingPlace}
                      onChange={(e) => {
                        setApplicantName({ ...applicantName, hoardingPlace: e.target.value });
                      }}
                    >
                      <MenuItem value={'Ground'}>Ground</MenuItem>
                      <MenuItem value={'Terrace'}>Terrace</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel id="demo-simple-select-label">Dimensions: </InputLabel>
                  <FormControl fullWidth>
                    <DimensionDataTableForPrivate dimensionData={advertisementDetails} />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel id="demo-simple-select-label">जागामालकाचे नाव: </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      value={advertisementDetails.landlord.name}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          landlord: {
                            ...prevDetails.landlord,
                            name: e.target.value,
                          },
                        }));
                      }}
                      label="Name of the Landlord"
                    />
                    {/* <FormLabel id="demo-radio-buttons-group-label">NOC of the Landlord submitted</FormLabel> */}
                    <FormLabel id="demo-radio-buttons-group-label">जागामालकाचे एनओसी सादर केली:</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={advertisementDetails.landlord.nocSubmitted}
                      onChange={(e) => {
                        setAdvertisementDetails((prevDetails) => ({
                          ...prevDetails,
                          landlord: {
                            ...prevDetails.landlord,
                            nocSubmitted: e.target.value,
                          },
                        }));
                      }}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <div>
                    <InputLabel>कागदपत्रे निवडा:</InputLabel>
                    <br />
                    <FormControl sx={{ m: 1, width: 350 }}>
                      <InputLabel id="demo-multiple-chip-label">Documents</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={8} xs={12}>
                  <InputLabel>
                    सर्व कागदपत्रे एका PDF मध्ये जोडणे आवश्यक आहे (फाइल प्रकार: PDF आणि फाइल आकार: 20 MB पेक्षा कमी)
                  </InputLabel>
                  {advertisementDetails.bannerDesign.preview ? (
                    <>
                      <CancelOutlinedIcon onClick={removeBannerDesign} />
                      <img src={advertisementDetails.bannerDesign.preview} width={500} height={220} alt="Profile" />
                    </>
                  ) : (
                    <UploadSingleFile maxSize={5145728} accept="application/pdf" onDrop={handleBannerDesign} />
                  )}
                </Grid>
                <Grid item md={4} xs={12} />

                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Stability Issue Date"
                        value={advertisementDetails.stabilityIssueDate}
                        onChange={(newValue) => {
                          setAdvertisementDetails({ ...advertisementDetails, stabilityIssueDate: newValue });
                        }}
                        format="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Stability Valid Till"
                        value={advertisementDetails.stabilityValidTill}
                        onChange={(newValue) => {
                          setAdvertisementDetails({ ...advertisementDetails, stabilityValidTill: newValue });
                        }}
                        format="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    name="engineerName"
                    label="Engineer Name"
                    value={applicantName.engineerName}
                    onChange={(e) => {
                      setAdvertisementDetails({ ...advertisementDetails, engineerName: e.target.value });
                    }}
                  />
                </Grid>

                {/* <Grid item md={6} xs={12}>
                  <InputLabel>NOC From Police Station (File Type: jpeg,png and File Size: less than 5 mb)</InputLabel>
                  {advertisementDetails.policeNoc.preview ? (
                    <>
                      <CancelOutlinedIcon onClick={removePoliceNoc} />
                      <img src={advertisementDetails.policeNoc.preview} width={500} height={220} alt="Profile" />
                    </>
                  ) : (
                    <UploadSingleFile maxSize={5145728} accept="image/*" onDrop={handlepoliceNoc} />
                  )}
                </Grid> */}

                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <FormGroup>
                      <FormControlLabel
                        required
                        control={<Checkbox />}
                        label="मी जळगाव शहर  महानगरपालिकेने जाहिरात बॅनर/फलक/होर्डींग्स करीता विहित केलेल्या अटी व शर्ती  तसेच महाराष्ट्र महानगरपालिका (आकाश चिन्हे आणि जाहिरातींच्या प्रदर्शनाचे नियमन आणि नियंत्रण) मार्गदर्शक तत्त्वे, 202२ मधील नियमावली व तरतूदी काळजीपूर्वक वाचल्या आहेत आणि त्या सर्व अटी व शर्ती मला मान्य असून त्याचे मी तंतोतंत पालन करेल. माझ्याद्वारे सादर केलेली माहिती खोटी आढळल्यास तसेच माझ्याकडून महानगरपालिकेच्या तसेच शासनाने जाहिरात बॅनर/फलक/होर्डींग्स करीता विहित केलेल्या अटी शर्तींचे उल्लंघन झाल्यास, कायद्यानुसार विहित केलेल्या अशा सर्व दंडात्मक कारवाईसाठी मी जबाबदार आहे, याची मला जाणीव आहे. तसेच सदर जाहिरात होइडिंग्समुळे, व जाहिरातीच्या सरंचनमुळे कोणतेही जीवित हानी अगर वित्तीय हानी झाल्यास त्यास संपुर्णपणे मी जबाबदार राहील."
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Button
              sx={{ width: '150px' }}
              disabled={loading}
              type="submit"
              onClick={submitHandler}
              size="large"
              color="primary"
              variant="contained"
            >
              {loading ? <CircularProgress /> : 'Create'}
            </Button>
          </Box>
        </Card>
      </Container>
    </>
  );
}
