import React, { useState, useEffect } from 'react';
import { Modal, Paper, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const CommentModalView = ({ open, onClose, remark }) => {
  useEffect(() => {}, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{ p: 4, width: 500, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {remark?.map((remarkElement, index) => (
            <>
              <ListItem key={index} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={remarkElement?.role} src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary={remarkElement?.role?.toUpperCase()}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        {remarkElement.email || 'No Email'}
                      </Typography>
                      <br />
                      <Typography component="span" variant="p" color="text.primary">
                        Remark : {remarkElement.comment || 'No Remark'}
                      </Typography>
                      <br />
                      {remarkElement.isApproved === false ? (
                        <Typography component="span" variant="p" color="red">
                          Rejected by {remarkElement?.role}
                        </Typography>
                      ) : (
                        <Typography component="span" variant="p" color="green">
                          Verified by {remarkElement?.role}
                        </Typography>
                      )}
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))}

          <Button variant="contained" color="error" onClick={onClose} sx={{ mt: 2, ml: 3 }}>
            Close
          </Button>
        </List>
      </Paper>
    </Modal>
  );
};

export default CommentModalView;
